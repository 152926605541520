import React, { useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import Metatag from "../../hooks/Metatag";
import { Container, Row } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function ErrorPage() {
    useEffect(() => {
        // add class for home header and remove inner class
        $(".fixed-top").addClass("bg-header");
    
        // initiate the wow effect
        new WOW.WOW({
          live: false,
        }).init();
    
      }, []);
    

  return (
    <>
         <Header main_className="bg-header"></Header>

        <Metatag title='500 - Something Went Wrong'
         description='500 - Something Went Wrong'
         keywords="500 - Something Went Wrong"
         error="1"
         />
        
        <Container className="border-1 border-primary py-md-5 my-5">
            <Row class="mb-3 mb-lg-5 pb-lg-4">
                <div class="col-xl-12 text-center">
                    <div class="p-3 p-lg-4">
                      <div class="col-lg-9 mx-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="100px" class="bi bi-exclamation-triangle fa-5x text-primary mt-4 mb-4" viewBox="0 0 16 16" >
                                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                  </svg>
                          <h1 class="h2 text-primary fw-bold">Sorry, Something Went Wrong.</h1>
                          <p class="mb-1 wow">We are sorry about the inconvenience, please visit this page later.</p>
                          <p class="text-center mb-5 wow">
                              Please go back to
                              <Link to={global.variables.strDomainUrl} contenteditable="false" style={{cursor: "pointer;"}} rel="noopener"> www.minds.iitb.ac.in</Link>
                          </p>
                      </div>
                    </div>
                </div>
            </Row>

        </Container>

         {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>500 - Something Went Wrong</Breadcrumb.Item>
      </Breadcrumb>
    </>
  )
}
