import React from "react";
import { Navigate } from "react-router-dom";
import About from "../pages/about";
import Home from "../pages/home";
import Home1 from "../pages/home/home1";
import People from '../pages/about/people';
import Faculty from '../pages/about/faculty';
import PeopleDetail from '../pages/about/faculty-info';
import Research from "../pages/research";
import ResearchProject from '../pages/research/researchproject';
import ResearchProjectDetails from '../pages/research/research-details';
import Publications from "../pages/research/publications";
import Academics from "../pages/academics";
import Admission from "../pages/academics/admission";
import AcademicsDetails from "../pages/academics/academicsdetails";
import Seminars from "../pages/seminars";
import SeminarsDetails from "../pages/seminars/seminars-details";
import News from "../pages/news-events/news";
import NewsDetails from "../pages/news-events/news-details";
import Events from "../pages/news-events/events";
import EventsDetails from "../pages/news-events/events-details";
import Reports from "../pages/news-events/reports";
import Newsletter from "../pages/news-events/newsletter";
import Collaboration from "../pages/collaboration";
import CollaborationDetails from "../pages/collaboration/collaborationdetails";
import Contact from "../pages/contact";
import Recruitment from "../pages/recruitment";
import Search from "../pages/layout/searchresults";
import Sitemap from "../pages/sitemap/sitemap";
import PageNotFound from "../pages/layout/pageNotFound";
import ErrorPage from "../pages/layout/errorPage";
import FoundersAndDonors from "../pages/about/founder-donors";
import PublicationDetails from "../pages/research/publication-details";
import AdmissionDetails from "../pages/academics/admission-details";

const websiteRoutes = [

    { path: "/home", component: <Home /> },
    { path: "/home1", component: <Home1 /> },
    { path: "/about", component: <About /> },
    { path: "/people", component: <People /> },
    { path: "/people/:pcategory_pagename", component: <Faculty /> },
    { path: "/people/:pcategory_pagename/:people_pagename", component: <PeopleDetail /> },
    { path: "/research", component: <Research /> },
    { path: "/research-project/:rcategory_id", component: <ResearchProject /> },
    { path: "/research-details/:res_pagename", component: <ResearchProjectDetails /> },
    // { path: "/publications/:pub_year", component: <Publications /> },
    { path: "/publications", component: <Publications /> },
    { path: "/publication-details/:pub_pagename", component: <PublicationDetails /> },
    { path: "/academics", component: <Academics /> },
    { path: "/academic-details/:page_name", component: <AcademicsDetails /> },
    { path: "/admissions", component: <Admission /> },
    // { path: "/seminars", component: <Seminars /> },
    { path: "/seminars/:event_year", component: <Seminars /> },
    { path: "/seminars-details/:page_name", component: <SeminarsDetails /> },
    { path: "/news/:news_year", component: <News /> },
    { path: "/news-details/:page_name", component: <NewsDetails /> },
    { path: "/admissions/:admission_page_name", component: <AdmissionDetails /> },
    // { path: "/events", component: <Events /> },
    { path: "/events/:event_year", component: <Events /> },
    { path: "/events-details/:page_name", component: <EventsDetails /> },
    { path: "/search-results/:str", component: <Search/> },
    { path: "/reports/:report_year", component: <Reports /> },
    { path: "/newsletter/:nletter_year", component: <Newsletter /> },
    { path: "/Collaboration", component: <Collaboration /> }, 
    { path: "/collaborationdetails", component: <CollaborationDetails /> }, 
    { path: "/contact", component: <Contact /> }, 
    { path: "/recruitment", component: <Recruitment /> }, 
    { path: "/Sitemap", component: <Sitemap /> }, 
    { path: "/", component: <Home /> },
    { path: "/about/:founder_pagename", component: <FoundersAndDonors /> },
    { path: "*" , component: <PageNotFound/>},
    { path: "/error-page" , component: <ErrorPage/>}
];

export { websiteRoutes };
  