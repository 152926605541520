import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row} from 'reactstrap';
import globalVariables from '../../global-variables';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";

export default function PeopleDetails(props) {
    const [show, setShow] = useState(false);
    
    const peoplecategory = props.peoplecategory
    const qs_pcategory_pagename = props.qs_pcategory_pagename

    //variable to close left navbar on link click
    const [expanded, setExpanded] = useState(false);
    
    return (
        <Navbar expanded={expanded} collapseonselect expand="md" className='d-block p-0 container '>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                <Link style={{border : '1px solid #dee2e6'}} className="btn btn-outline-secondary px-4 py-2 w-100 text-start d-flex justify-content-between rounded-0 align-items-center d-md-none">{props.submenutitle} <HiOutlineMenuAlt2 /></Link>
            </Navbar.Toggle>
            <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                    <ul className='left-navbar bg-white py-md-2 py-3 px-2 w-100 mb-3 d-md-flex justify-content-center people-nav mb-5'>
                   
                            { 
                                peoplecategory.map((val)=>{
                                    return(
                                        <>
                                            {
                                               val.pcategory_id === globalVariables.variables.strPeopleCategoty_id ? null
                                               :<li><Link to={globalVariables.variables.strDomainUrl + 'people/' + val.pcategory_pagename} className={`${qs_pcategory_pagename === val.pcategory_pagename ?"active" : null} `} onClick={props.tabclick}>{val.pcategory_name}</Link></li>
                                            }
                                        </>
                                        
                                        
                                        
                                    )

                                })
                                    
                            }
                    </ul>
        </Navbar.Offcanvas>
        </Navbar>
    )
} 