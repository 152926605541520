import React from "react";
import Header from "../layout/header";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import global from "../../global-variables";
import { GetCareerList } from "../../api/career-functions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import Metatag from "../../hooks/Metatag";

export default function Recruitment() {
  const [careerList, setCareerList] = useState(null);

  const navigate =  useNavigate();

  // success function when the list is retrived
  const OnGetCareerSuccess = (p_response) => {
    // set the list for looping
    setCareerList(p_response);
  };

  // things to be done when the page is loaded
  useEffect(() => {
    //make the object for getting the news type year list
    let _objCareerList = new Object();
    _objCareerList.career_status = 1;

    // call the function to get the news type year list
    GetCareerList(_objCareerList, OnGetCareerSuccess,navigate);
  }, []);
  return (
    <>
      <Header main_class="bg-header-home"></Header>
      
      <Metatag title="Jobs at Centre for Machine Intelligence & Data Science IIT Bombay"
               keywords="Work with Centre for Machine Intelligence & Data Science IIT Bombay, Jobs at CMinds IIT Bombay, Work with IIT Bombay, Job Openings at AIML Centre at IIT Bombay"
               description="Work at Centre for Machine Intelligence & Data Science IIT Bombay "/>

      {/* Visual Container */}
      <div className="container-fluid visual-container-1920 px-0">
        <div
          className="bg-visual-inside position-relative"
          style={{ backgroundImage: "url('./images/masthead-careers.jpg')" }}
        >
          <img
            src={global.variables.strDomainUrl + "images/masthead-spacer.png"}
            className="img-fluid"
            alt=""
            title=""
          />
          <Container className="visual-text mx-auto">
            <h1 className="fw-bold">Recruitment</h1>
          </Container>
        </div>
      </div>

      {/* Body Panel */}
      <div className="container-fluid px-0 my-xl-5 my-4">
        <Container className="py-md-5">
          <div className="border-box-1">
            <Row className="px-md-3">
              <Col className="col-lg-7 col-xl-8">
                <div className="h-100 py-lg-5">
                  <div className="d-flex flex-column h-100 z-index-2 position-relative">
                    <div className="px-lg-5">
                      <div className="hd mb-4">
                        <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                          <span>C-MInDS</span> Careers
                        </h2>
                      </div>
                      <div className="wow fadeInUp">
                        <p>
                          CMInDS is looking for exceptional candidates who have
                          demonstrated strong research potential or leadership
                          in theoretical and applied aspects related to Machine
                          Intelligence and Data Science.
                        </p>
                        <p>
                          Applications are invited for faculty positions at all
                          levels (Assistant Professor/Associate
                          Professor/Professor).
                        </p>
                        <p>
                          Candidates must have a Ph.D. in Computer Science or
                          Data Science or other related areas. It is desirable
                          that candidates have showcased their research in the
                          form of publications at top Machine Intelligence/Data
                          Science journals and/or conferences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-lg-5 col-xl-4 text-end d-lg-block d-none align-self-center z-index-2">
                <img
                  src={global.variables.strDomainUrl + "images/pic-careers.jpg"}
                  className="img-fluid wow fadeInRight"
                  alt=""
                  title=""
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* <Container>
                <Row>
                    <Col>
                        <h1 className="fs-2">Current Openings</h1>
                    </Col>
                </Row>
            </Container> */}
      <div className="container-fluid bg-light py-5">
        <Container className="py-lg-5 px-0">
          <div class="hd mb-4 justify-content-center">
            <h2 class="fw-bolder h1 m-0 wow fadeInRight">
              <span>Current</span> Openings
            </h2>
          </div>
          <Row>
            {careerList != null ? (
              careerList.length > 0 ? (
                <Col md={10} className="mx-auto mb-2">
                      <Accordion defaultActiveKey="" flush>
                      {
                        careerList.map((val,index) => {
                          const scrollToPosition = () => {
                            const position =  900 + index * 30; // Adjust the calculation based on your requirements
                            window.scrollTo({ top: position, left: 0, behavior: 'smooth' });
                        };
                          return (
                            <Accordion.Item eventKey={index.toString()} key={val.career_id}>
                              <Accordion.Header onClick={scrollToPosition}>
                                <h5 className="m-0">
                                  {val.career_name} - {val.career_location}
                                </h5>
                              </Accordion.Header>
                              <Accordion.Body>
                                <span className="ck-content"
                                  dangerouslySetInnerHTML={{
                                    __html: val.career_desc,
                                  }}
                                ></span>
                                {
                                  val.career_apply_url ?
                                    <div className="pt-3 wow fadeInUp">
                                      <a target="blank" href={val.career_apply_url}
                                        className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                        Apply Now
                                      </a>
                                    </div>
                                  :
                                  null
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })        
                      }
                      </Accordion>
                    </Col>
              ) : (
                <div className="container-fluid px-0 my-md-5 my-4">
                  <Container>
                    <Row>
                      <span className="text-center">
                        <b>Jobs Not Found</b>
                      </span>
                    </Row>
                  </Container>
                </div>
              )
            ) : (
              <div className="container-fluid px-0 my-md-5 my-4">
                <Container>
                  <Row>
                    <span className="text-center">Loading...</span>
                  </Row>
                </Container>
              </div>
            )}
          </Row>
        </Container>
      </div>
      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Recruitment</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}
