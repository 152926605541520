import React, { useState, useEffect } from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link,useNavigate  } from "react-router-dom";
import axios  from "axios";
import Metatag from "../../hooks/Metatag";

export default function Contact() {


    const [setting, setSettingList] = useState([]);
	const navigate =  useNavigate();
    
    useEffect(()=>{
            let _obj = new Object();
            _obj.setting_id = global.variables.strSetting_id;
            axios.post(global.variables.strApiUrl + "/settingApi/getCompanySettingList",_obj)
            .then((response_list) => {
                
                if(response_list.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    // pass the result in the success function of the respective page
                    setSettingList(response_list.data[0]);
                }
             
            });
    },[])

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="Centre for Machine Intelligence & Data Science IIT Bombay Contact Information"
                     keywords="Centre for Machine Intelligence & Data Science IIT Bombay Contact Information, CMinds Contact details, CMinds at IIT Bombay, AIML Training in Mumbai, Artificial Intelligence Training in Mumbai, Machine Learning Training in Mumbai, Data Science Training in Mumbai, PhD in Data Science in Mumbai, MS in Data Science in Mumbai"
                     description="Contact Information of Centre for Machine Intelligence & Data Science IIT Bombay "/>
            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-contact.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-bold">Contact</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid px-0 my-md-5 my-4">
                <Container className="py-xl-5">
                    <div className="border-box-1">
                    <Row className="px-md-3">
                        <Col className="col-lg-6 col-xl-6 col-12 order-2 order-lg-1">
                            <div className="h-100 py-lg-5 py-3">
                                <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                    <div className="px-md-5">
                                        <div className="hd mb-4">
                                            <h2 className="fw-bolder h1 m-0 wow fadeInRight"><span>Contact</span> C-MInDS</h2>
                                        </div>
                                        <div className="wow fadeInUp">
                                            
                                            {
                                                setting.map((val)=>{
                                                    return(
                                                        <>
                                                            <p><b>{val.setting_company}</b></p>
                                                            <p>{val.setting_address + ","}<br/>{val.setting_city } { val.setting_pin}{
                                                                val.setting_country != null && val.setting_country != "" ? <>{", "+ val.setting_country}</> : null  
                                                            }</p>

                                                            <p>
                                                               
                                                                Email : <span>{val.setting_contact_email}</span>
                                                                
                                                                {
                                                                     val.setting_phone != null && val.setting_phone != "" ? 
                                                                     <span><br/>Contact : {val.setting_phone}</span>
                                                                     : null
                                                                }
                                                                {
                                                                    val.setting_mobile != null && val.setting_mobile != "" ? 
                                                                    <span><br/>Mobile : {val.setting_mobile}</span>
                                                                    : null
                                                                }
                                                            </p>

                                                            
                                                        </>
                                                    )
                                                   

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* Iframe */}
                        {
                                setting.map((val)=>{

                                    return(
                                        <>
                                            {
                                                val.setting_directionmap != null && val.setting_directionmap != "" ?
                                                <Col className="col-lg-6 col-xl-6 col-12 py-lg-5 text-end align-self-center order-1 order-lg-2 z-index-2">
                                                    <div className="p-0 px-md-0 py-md-3 p-lg-0">
                                                        <div className="ratio ratio-16x9" dangerouslySetInnerHTML={{ __html: val.setting_directionmap }}>
                                                        </div>
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                        </>
                                    )})

                        }
                       
                    </Row>
                </div>
                </Container>
            </div>


            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
            <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Contact</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};