import { PublicationsApiUrls,PublicationOnHomePageUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";

export const GetPublicationsList = (p_request_object, p_success_function,navigate ) => {
	
    //Get Publication List
	axios.post(globalVariables.variables.strApiUrl + PublicationsApiUrls.get_publications_api, p_request_object)
	.then((response_list) => {
		
		if(response_list.data === "error")
		{
			navigate("/error-page")
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
	});

};

export const GetPublicationOnHomePageList = (p_success_function,navigate) => {

    //Get Publication List
	axios.get(globalVariables.variables.strApiUrl + PublicationOnHomePageUrls.get_publication_onhomepage_api)
	.then((response_list) => {
        
		if(response_list.data === "error")
		{
			navigate("/error-page")
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
       
	});

};