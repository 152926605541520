import { NewsLetterDistinctYearApiUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";
import { json } from "react-router-dom";

export const GetNewsLetterDistinctYearList = (p_request_object, p_success_function,navigate) => {
    //Get news type yesr List
	axios.post(globalVariables.variables.strApiUrl + NewsLetterDistinctYearApiUrls.get_newsletter_distinct_year_api, p_request_object)
	.then((response_list) => {

		if(response_list.data === "error")
        {
             navigate("/error-page")
        }
        else
        {
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
      
	});

};