import React, { useEffect, useState } from 'react';
import axios from 'axios';
import globalVariables from '../global-variables';
import {useNavigate} from 'react-router-dom'
import { Input } from "reactstrap";

const NewsYearWiseFilter = (props) => {

    const navigate = useNavigate();
    
    function OnchangeYear(e) {

        // redirecting to the supplied url
        navigate(`/${props.navigate_url}/${e.target.value}`)
    }


    return (
        <React.Fragment>
            <div className="me-3">
                <Input
                    name="selected_year"
                    type="select"
                    id={props._id}
                    className="rounded-0 shadow-none"
                    onChange={(e)=>{OnchangeYear(e)}}
                >
                    {  props.yearList.length > 0 ?
                        props.yearList.map((val) => {
                            return (
                                <option value={val.news_year} key={val.news_year}>{val.news_year}</option>
                            );

                        })
                    :
                    <>
                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                    
                    </>
                    }

                </Input>
            </div>
        </React.Fragment>
    )
};
export default NewsYearWiseFilter;

