import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link } from "react-router-dom";
import axios from "axios";
import Metatag from "../../hooks/Metatag";
import WOW from 'wowjs'

export default function Academics() {

    let _count = 0;
    const [academics, setAcademicsList] = useState([]);

    const [boolval, setBoolval] = useState(true);
    const navigate =  useNavigate();

    useEffect(()=>{
        let _obj = new Object();
        _obj.acad_status =1;

        axios.post(global.variables.strApiUrl + "/acadApi/getacadList",_obj)
        .then((response_list) => {

            if(response_list.data === "error")
            {
                 navigate("/error-page")
            }
            else
            {
                 // pass the result in the success function of the respective page
                 setAcademicsList(response_list.data[0]);
            }
        
        });

    },[])

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, [academics]);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="Artificial Intelligence Academic Programs | Machine Learning Academic Programs"
                     keywords="Artificial Intelligence Academic Programs, Machine Learning Academic Programs, Data Science Academic Programs, Doctor of Philosophy in DS & AI, MS by Research in DS & AI, Inter-Disciplinary Dual Degree Program (IDDDP), Minor in AI & DS"
                     description="C-MInDS by IIT Bombay offers one of India's most comprehensive and advanced academic programs in Data Science and AI"/>
            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-academics.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-bold">Academics</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid px-0 mt-md-5 py-4">
                <Container className="text-center col-xxl-10 col-lg-9 col-md-11 mx-auto">
                    <div className="hd mb-3 justify-content-center">
                        <h2 className="fw-bolder h1 m-0 wow fadeInUp"><span>Academics</span> Overview</h2>
                    </div>
                    <h5>C-MInDS offers one of India's most comprehensive and advanced academic programs in <br className="d-xl-block d-none"/>Data Science and AI in the country.</h5>
                </Container>
            </div>

                {
                    academics != null ? 

                    academics.length > 0 ? 
            
                    <div className="container-fluid px-0 px-md-3">

                        {
                            academics.map((val,index) => { 
                               
                                return (
                                    <>
                                        { 
                                            (++index) % 2 != 0 ? 
                                            <Container className="py-md-5 py-4" key={val.acad_id}>
                                                <div className="border-box-2">
                                                    <Row className="px-md-3">
                                                        <Col className="col-lg-7 col-xl-8 col-md-7 col-12 order-xl-2 order-2">
                                                            <div className="h-100 py-lg-5 py-md-4">
                                                                <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                                                    <div className="px-lg-5">
                                                                        <h3 className="h5 mb-3 wow fadeInUp">{val.acad_course_name}</h3>
                                                                        {
                                                                            val.acad_brief != null && val.acad_brief != "" ? 
                                                                            <p className="wow fadeInUp">{val.acad_brief}</p> : 
                                                                            null
                                                                        }
                                                                        
                                                                        <div className="pt-4 wow fadeInUp">
                                                                            <Link to={global.variables.strDomainUrl + "academic-details/" + val.acad_pagename} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-5 col-xl-4 col-md-5 col-12 text-end align-self-lg-center z-index-2 order-xl-1 order-1">
                                                            <Link to={global.variables.strDomainUrl + "academic-details/" + val.acad_pagename} className="shiny position-relative overflow-hidden my-lg-5 my-md-4 mt-0 mb-3 d-block">
                                                            <div className="bg-pic-academics">
                                                        {
                                                            
                                                            val.acad_image != null && val.acad_image != "" ?
                                                            <div className='bg-picacademics' style={{ backgroundImage: "url('" + global.variables.strApiUrl + "/academic-image/" + val.acad_image + "')" }}>
                                                            <img src={global.variables.strDomainUrl + "images/bg-academics.png"} className="w-100 img-fluid wow fadeIn" alt={val.acad_pagetitle} title={val.acad_pagetitle} />
                                                            </div>
                                                            :
                                                            <img src={global.variables.strDomainUrl + "images/academics-default.png"} className="img-fluid wow fadeIn" alt="default image" title="default image" />
                                                        }    
                                                            </div>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Container>
                                            : 
                                            <Container className="py-md-5 py-4">
                                                <div className="border-box-1">
                                                    <Row className="px-md-3">
                                                        <Col className="col-lg-7 col-xl-8 col-md-7 col-12 order-md-1 order-2">
                                                            <div className="h-100 py-lg-5 py-md-4">
                                                                <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                                                    <div className="px-lg-5 px-md-3">
                                                                        <h3 className="h5 mb-3 wow fadeInUp">{val.acad_course_name}</h3>
                                                                        {
                                                                                val.acad_brief != null && val.acad_brief != "" ? 
                                                                                <p className="wow fadeInUp">{val.acad_brief}</p> : 
                                                                                null
                                                                        }
                                                                        <div className="pt-4 wow fadeInUp">
                                                                            <Link to={ global.variables.strDomainUrl + "academic-details/" + val.acad_pagename} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-5 col-xl-4 col-md-5 col-12 text-end align-self-lg-center z-index-2 order-md-2 order-1">
                                                            <Link to={ global.variables.strDomainUrl  + "academic-details/" + val.acad_pagename} className="shiny position-relative overflow-hidden my-lg-5 my-md-4 mt-0 mb-3 d-block">

                                                                <div className="bg-pic-academics">

                                                                {
                                                                
                                                                    val.acad_image != null && val.acad_image != "" ?
                                                                    // <img src={global.variables.strApiUrl + "/academic-image/" + val.acad_image} className="img-fluid wow fadeIn" alt={val.acad_pagetitle} title={val.acad_pagetitle} />
                                                                    <div className='bg-picacademics' style={{ backgroundImage: "url('" + global.variables.strApiUrl + "/academic-image/" + val.acad_image + "')" }}>
                                                                    <img src={global.variables.strDomainUrl + "images/bg-academics.png"} className="w-100 img-fluid wow fadeIn" alt={val.acad_pagetitle} title={val.acad_pagetitle} />
                                                                    </div>
                                                                    :
                                                                    <img src={global.variables.strDomainUrl + "images/academics-default.png"} className="img-fluid wow fadeIn" alt="default image" title="default image" />
                                                                } 
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Container>

                                        }
                                    </>    
                                )
                            })
                        }
                        
                        
                    </div>
                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container >
                            <Row>
                                <span className="text-center">No Records Found</span>
                            </Row>
                        </Container>
                    </div>
                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container>
                            <Row>
                                <span className="text-center">Loading...</span>
                            </Row>
                        </Container>
                    </div>

                }

                

            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
            <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "academics"}>Academics</Breadcrumb.Item>
                <Breadcrumb.Item active>Overview</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};