import React from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";
import Metatag from "../../hooks/Metatag";
import { Link } from "react-router-dom";

export default function Collaboration() {
    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="Collaboration of CMinds IIT Bombay | Collaboration for AIML"
                keywords="Collaboration of CMinds IIT Bombay, Collaboration for Artificial Intelligence, Collaboration for Machine Learning, Collaboration for Data Science"
                description="Collaboration for Artificial Intelligence, Machine Learning and Data Science by CMinds IIT Bombay" />
            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-collaboration.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">Our Collaborations</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid px-0 my-md-5 my-4">
                <Container className="py-xl-5">
                    <div className="mb-5 text-center">
                        <div className="hd1 text-center mb-3">
                            <h2 className="fw-bolder h1 m-0 wow fadeInUp"><span></span></h2>
                        </div>
                        <div className="col-lg-10 col-xl-10 m-auto wow fadeInUp">
                            <p>At CMInDS, we engage with a diverse spectrum of partners, including government agencies, public institutions, and private companies. These collaborations fuel a dynamic exchange of ideas, expertise, and resources, driving impactful solutions across various sectors.</p>
                            <p className="m-0">We have collaborated with organizations on applications of AI/ML in Indian Language & Speech Technologies, Healthcare and Life Sciences, Transportation, Industry 4.0, as well as analytics for the banking and financial the sector.</p>
                        </div>
                    </div>
                    <Row className="row-cols-1 row-cols-md-3 row-cols-xl-4 g-md-5 g-3 justify-content-center">
                        <Col>
                            <div className="p-4 border border-primary h-100">
                                <div>
                                    <h6 className="wow fadeInUp">Projects driven by GoI & GoM</h6>
                                    <ul>
                                        <li className="wow fadeInUp">MEITy</li>
                                        <li className="wow fadeInUp">RT&H</li>
                                        <li className="wow fadeInUp">MCGM</li>
                                        <li className="wow fadeInUp">MoE (Bhashini, Swayam, NPTEL)</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="p-4 border border-primary h-100">
                                <div>
                                <h6 className="wow fadeInUp">Projects by public agencies</h6>
                                    <ul>
                                        <li className="wow fadeInUp">RBI</li>
                                        <li className="wow fadeInUp">NSE</li>
                                        <li className="wow fadeInUp">BoB</li>
                                        <li className="wow fadeInUp">CAG</li>
                                        <li className="wow fadeInUp">SEBI</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="p-4 border border-primary h-100">
                                <div>
                                <h6 className="wow fadeInUp">Projects from private companies</h6>
                                    <ul>
                                        <li className="wow fadeInUp">Google</li>
                                        <li className="wow fadeInUp">Gupshup</li>
                                        <li className="wow fadeInUp">Michelin</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="py-xl-5 mt-5 mt-md-0 d-none">
                    <div className="border-1 border-primary">
                        <Col>
                            <div className="h-100 p-lg-5">
                                <div className="postion-relative mb-3">
                                    <div class="row align-items-center">
                                        <div class="mb-4 mb-md-0 text-start col-md-4 col-lg-3 ">
                                            <div className="bg-light py-5 px-5">
                                            <h4 class="m-0 text-primary">C-MInDS & <br></br>SBI Foundation</h4>
                                            </div>
                                        </div>
                                        <div class="px-xl-4 col-md-7 col-lg-9">
                                            <div class="">
                                                <p>Building world-class research in strategic area of <b>Finance and Banking</b></p>
                                                <p className="m-0">A multi-year agreement between CMInDS and SBI Foundation to address BFSI sector's key challenges by leveraging IIT Bombay's strong multidisciplinary research culture and AI and Data science expertise, together with the vast data available with SBI.</p>
                                            </div>
                                        </div>
                                        <hr class="my-5"></hr>
                                        <div className="ṇtext-center">
                                            <img src={global.variables.strDomainUrl + "images/cminds-and-sbi-foundation-3.jpg"} className="img-fluid wow fadeIn" alt="" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Container>
            </div>




            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Collaboration</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};