import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link , useNavigate } from 'react-router-dom';
import global from '../../global-variables';
import DefaultImg from '../../assets/default_img/people-default.jpg';
import BgPeopleImg from '../../assets/default_img/bg-people.png';
import { BsChevronRight } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import WOW from 'wowjs';
import { Container, Row, Col } from 'reactstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import globalVariables from '../../global-variables';

export function GetPeoples(props) {

    const [show, setShow] = useState(false);
    const [getfacultyid, setFacultyid] = useState('');
    const [getpeoplelist, setPeopleList] = useState([]);
    const handleClose = () => setShow(false);

    const navigate =  useNavigate();

    const handleShow = (e, p_people_id) => {

        e.preventDefault();

        setShow(true);
        //get faculty id
        setFacultyid(p_people_id);
    }

    useEffect(() => {
        let _obj = new Object();
        _obj.people_ps_category_id = props.subcategory_id;
        _obj.people_status = 1;


        axios.post(global.variables.strApiUrl + '/peopleApi/getpeopleList', _obj)
            .then((response_people) => {

                if(response_people.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setPeopleList(response_people.data[0]);
                }
               
            });
    }, [])

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, [getpeoplelist]);

    return (
        <React.Fragment>
            <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5 g-4">
                {
                    getpeoplelist.map((p_val) => {

                        return (
                            <Col key={p_val.people_id}>
                                <div className="bg-light d-flex flex-column h-100">
                                    <div className="pic position-relative overflow-hidden d-flex justify-content-center">
                                        <img src={BgPeopleImg} className="img-fluid w-100" alt="Default Img" title="Default Img" />
                                        <div className='z-index-1 d-flex align-items-center' style={{ position: "absolute", top: "0", bottom: "0" }} >
                                            {
                                                p_val.people_pic != null &&  p_val.people_pic != "" ?
                                                    <img src={global.variables.strApiUrl + "/people/" + p_val.people_pic} className="img-fluid" alt={p_val.people_name} title={p_val.people_name} /> :
                                                    <img src={DefaultImg} className="img-fluid" alt={p_val.people_name} title={p_val.people_name} />
                                            }
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h3 className="fw-semibold h6 lh-sm">{p_val.people_name}</h3>
                                        <p>{p_val.people_designation}</p>
                                    </div>
                                    {/* {
                                        (p_val.people_publications === null || p_val.people_publications === "") && (p_val.people_research_inst === null || p_val.people_research_inst === "") && (p_val.people_profile_brief === null || p_val.people_profile_brief === "") && (p_val.people_url != "" && p_val.people_url != null) ?
                                        <div className="p-3 mt-auto">
                                            <Link to={p_val.people_url} target="_blank">Read More<BsChevronRight className="ms-2" /></Link> 
                                        </div>
                                        :
                                       null
                                    } */}
                                    {/* read more link */}
                                    {/* {
                                        (p_val.people_publications != null && p_val.people_publications != "") || (p_val.people_research_inst != null && p_val.people_research_inst != "") || (p_val.people_profile_brief != null && p_val.people_profile_brief != "") ? */}
                                            <div className="p-3 mt-auto">
                                                <Link to={ globalVariables.variables.strDomainUrl + "people/" + p_val.pcategory_pagename + "/" + p_val.people_pagename}>Read More<BsChevronRight className="ms-2" /></Link> 
                                            </div>
                                             {/* :
                                            null
                                    } */}
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

        </React.Fragment>
    )

}  
