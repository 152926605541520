import { useState, useEffect } from "react";
import { GetNewsOnHomePageList } from "../../api/news-functions";
import globalVariables from "../../global-variables";
import { Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
    BsChevronRight,
    BsLink45Deg,
    BsImages,
    BsFilePdf,
    BsPlayBtn,
} from "react-icons/bs";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import FsLightbox from "fslightbox-react";
import NewsDefaultImg from '../../assets/default_img/news-default.png';

export default function NewsBlock() {

    // constant to be used on the page
    const [news, setNewsList] = useState(null);
    const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
    const [news_gallery, setNewsGallery] = useState([]);
    const [videos, setvideosToggler] = useState(false);
    const [lightbox_video, setLightBoxVideo] = useState([]);
    const [gallery_key, setGalleryKey] = useState("");
    const navigate = useNavigate();

    // success function when the list is retrived
    const OnGetNewsSuccess = (p_response) => {
        // set the list for looping
        setNewsList(p_response);
    }

    // success function when news gallery is retrived
    const OnGetNewsGallerySuccess = (p_response) => {

        let _arrGallery = [];

        // loop and make the array for binding the gallery
        for (let i = 0; i < p_response.length; i++) {
            _arrGallery.push(globalVariables.variables.strApiUrl + "/news-gallery-image/" + p_response[i].gallery_pic);
        }

        // set the array in the constant
        setNewsGallery(_arrGallery);

        setGalleryKey(Date.now())

        // using time out for the value to get saved in state 
        setTimeout(() => {
            // trigger the link click to display the news gallery popup
            document.getElementById("lnk_trigger_news_gallery_popup_home").click();
        }, 200);

    }

    // function to invoke photo gallery
    const InvokePhotoGallery = (e, p_news_id) => {

        e.preventDefault();

        // Make an object for getting news gallery
        let _objNewsGallery = new Object();
        _objNewsGallery.gallery_status = 1;
        _objNewsGallery.gallery_news_id = p_news_id;

        // call the function to get the news gallery
        GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);

    };

    // function to invoke video popup
    const InvokeVideoPopup = (e, p_news_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_news_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_home").click();

    };

    // things to be done when the page is loaded
    useEffect(() => {
        // call the function to get the roadblock
        GetNewsOnHomePageList(OnGetNewsSuccess, navigate);

    }, []);

    return (
        <>
            {
                news != null ?
                    news.length > 0 ?
                        <div className="pe-xl-5 col-xl-6">
                            <div className="hd mb-3 mb-lg-5 wow fadeInRight">
                                <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                                    <span>C-MInDS</span> News
                                </h2>
                            </div>
                            <Row className="row-cols-1 g-4 g-lg-5">
                                {
                                    news.map((val) => {
                                        return (
                                            <Col className="news" key={val.news_id}>
                                                <div className="d-md-flex bg-light position-relative overflow-hidden h-100 wow fadeInUp">
                                                    {
                                                        val.news_images != null && val.news_images != "null" && val.news_images != "" ?
                                                            <div className="pic d-flex justify-content-center wow fadeInUp">
                                                                <Link to={"/news-details/" + val.news_pagename} className="nav-link">
                                                                    <img
                                                                        src={
                                                                            globalVariables.variables.strApiUrl + "/news-main-image/" + val.news_images
                                                                        }
                                                                        className="img-fluid w-100"
                                                                        alt={val.news_headline}
                                                                        title={val.news_headline}
                                                                    />
                                                                </Link>
                                                            </div>
                                                            :
                                                            <div className="pic d-flex justify-content-center wow fadeInUp">
                                                                <Link to={"/news-details/" + val.news_pagename} className="nav-link">
                                                                    <img
                                                                        src={NewsDefaultImg}
                                                                        className="img-fluid w-100"
                                                                        alt={val.news_headline}
                                                                        title={val.news_headline}
                                                                    />
                                                                </Link>
                                                            </div>
                                                    }
                                                    <div className="d-flex justify-content-center flex-grow-1 wow fadeInUp">
                                                        <div className="px-4 py-md-3 py-4 w-100">
                                                            <Link to={"/news-details/" + val.news_pagename} className="nav-link">
                                                                <h5 className="fw-bold lh-sm">
                                                                    {val.news_headline}
                                                                </h5>
                                                            </Link>
                                                            <i className="mb-2">{val.news_date}</i>
                                                            <div className="d-flex gap-3 mt-2">

                                                                {/* link */}
                                                                {
                                                                    val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                                                        <a href={val.news_link} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsLink45Deg className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                                {/* Photo Gallery */}
                                                                {
                                                                    parseInt(val.news_gallery_count) > 0 ?
                                                                        <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokePhotoGallery(event, val.news_id)}><BsImages className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                                {/* Document */}
                                                                {
                                                                    val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                                                        <a href={globalVariables.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsFilePdf className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                                {/* Video */}
                                                                {
                                                                    val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                                                        <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokeVideoPopup(event, val.news_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>
                                                        <Link
                                                            to={"/news-details/" + val.news_pagename}
                                                            className="btnlink bg-primary d-flex align-items-center justify-content-center wow fadeInUp"
                                                        >
                                                            <BsChevronRight />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        )

                                    })

                                }

                            </Row>

                            <div className="mt-lg-5 mt-4 wow fadeInUp">
                                <Link
                                    to={news != null ? globalVariables.variables.strDomainUrl + "news/" + news[0].news_year : globalVariables.variables.strDomainUrl + "news/" + new Date().getFullYear()}
                                    className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal"
                                >
                                    View All
                                </Link>
                            </div>
                        </div>
                        :
                        <div className="pe-xl-5 col-xl-6 d-flex flex-column animated animated animated">
                            <div className="hd mb-3 mb-lg-5 wow fadeInRight">
                                <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                                    <span>C-MInDS</span> News
                                </h2>
                            </div>
                            <div class="d-md-flex border h-100 p-3 border-primary d-block" >
                                News Updates will be posted soon
                            </div>
                        </div>

                    :
                    <div className="mt-lg-5 mt-4 wow fadeInUp">
                        Loading ...
                    </div>
            }

            {/* Video popup */}
            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setvideosToggler(!videos);
            }}
                className="d-none"
                id="lnk_trigger_video_popup_home"></a>

            <FsLightbox
                toggler={videos}
                sources={lightbox_video}
            />

            {/* News Gallery popup */}
            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setNewsGalleryToggler(!news_gallery_toggler);
            }}
                className="d-none"
                id="lnk_trigger_news_gallery_popup_home"></a>

            <FsLightbox
                toggler={news_gallery_toggler}
                sources={news_gallery}
                key={gallery_key}
            />
        </>
    )
}