
// Roadblock Apis
export const RoadblockApiUrls = {
  get_roadblock_api : "/roadblockApi/getroadblockList"  
};

// Visual Panel Apis
export const VisualpanelApiUrls = {
  get_visualpanel_api : "/visualpanelApi/getvisualpanelList"  
};

// News / Admissions Apis
export const NewsApiUrls = {
  get_news_api : "/newsApi/getnewsList"  
};

// News gallery
export const NewsGalleryApiUrls = {
  get_news_gallery_api : "/newsgalleryApi/getnewsgalleryList"  
};


// event home Apis
export const EventOnHomePageApiUrls = {
  get_event_homepage_api : "/eventforhomepageApi/geteventforhomepageList"  
};

// news home Apis
export const NewsOnHomePageApiUrls = {
  get_news_homepage_api : "/newsApi/getnewsforhomepageList"  
};

// event Apis
export const EventApiUrls = {
  get_event_api : "/eventApi/geteventList"  
};

// Event gallery Apis
export const EventGalleryApiUrls = {
  get_event_gallery_api : "/eventgalleryApi/geteventgalleryList"  
};

// News / Year Apis
export const NewsTypeDistinctYearApiUrls = {
  get_news_type_distinct_year_api : "/newsApi/getnewstypedistinctyear"  
};

// Report api
export const ReportApiUrls = {
  get_report_api : '/reportApi/getreportList'
};

// Report / Year api
export const ReportDistinctYearApiUrls = {
  get_report_distinct_year_api : '/reportApi/getreportdistinctyearForWebadmin'
}

// NewsLetter api
export const NewsLetterApiUrls = {
  get_newsletter_api : '/nletterApi/getnletterList'
};

// NewsLetter / Year api
export const NewsLetterDistinctYearApiUrls = {
  get_newsletter_distinct_year_api : '/nletterApi/getnletterdistinctyear'
}

//Publication api
export const PublicationsApiUrls = {
  get_publications_api : '/publicationsApi/getpublicationsList'
};

//publication year api
export const PublicationDistinctYearApiUrls = {
  get_publication_distinct_year_api : "/publicationsApi/getpublicationsdistinctyearForWebadmin"  
};

//Career api
export const CareersApiUrls = {
  get_career_api : '/careerApi/getCareerList'
}

//event api
export const EventDisYearApiUrls = {
  get_event_disyear_api : '/eventApi/getEventdistinctyear'
}

export const PublicationOnHomePageUrls = {
  get_publication_onhomepage_api :'/publicationforhomepageApi/getpublicationforhomepageList'
}

