import React, { useEffect, useState } from "react";
import axios from "axios";
import global from "../../global-variables";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BsChevronRight } from "react-icons/bs";
import Header from "../layout/header";
import { Container, Row, Col } from "reactstrap";
import { json, useParams, useNavigate} from "react-router-dom";
import Parser from "html-react-parser";
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";

export default function Searchresults() {
  // secation name initialise
  let val_section_name = "";
  let bln_ul_opened = false;
  let _str_html = "";

  //Getting id from url
  let { str } = useParams();
  const qs_str = str;

  const [search, setSearchList] = useState([]);
  const navigate =  useNavigate();

  useEffect(() => {
    let _obj = new Object();
    _obj.searchstr = qs_str;
    setTimeout(() => {
      axios.post(global.variables.strApiUrl + "/searchApi/searchApi", _obj)
        .then((response_list) => {

          if(response_list.data === "error")
          {
               navigate("/error-page")
          }
          else
          {
            // pass the result in the success function of the respective page
            setSearchList(response_list.data[0]);
            
          }
          
        });
    }, 300);
  },[qs_str]);

  return (
    <>
      <Header main_class="bg-header-home"></Header>
      {
        qs_str != undefined ?
        <Metatag title={"Search Result for "+qs_str} keywords={"Search Result for "+qs_str} description={"Search Result for "+qs_str}/>
        : 
        null
      }
      {/* Visual Container */}
      <div className="container-fluid visual-container-1920 px-0">
        <div
          className="bg-visual-inside position-relative"
          style={{ backgroundImage: "url('../images/masthead-about.jpg')" }}
        >
          <img
            src={global.variables.strDomainUrl + "images/masthead-spacer.png"}
            className="img-fluid"
            alt=""
            title=""
          />
          <Container className="visual-text mx-auto">
            <h1 className="fw-semibold wow fadeInUp">Search Results</h1>
          </Container>
        </div>
      </div>

      {/* Body Panel */}
      <div className="container-fluid px-0 my-md-5">
        <Container className="py-lg-5">
          <div className="border-1 border-primary p-5 list">
            <h4 className="text-dark mb-4">
              {" "}
              Search results for '{qs_str}' -{" "}
              <small>({search.length} Records found)</small>
            </h4>
            {search.length > 0
              ? search.map((val, index) => {
                  // check if the section name doesnot match then
                  if (val_section_name != val.section_name) {
                    // set the new section value
                    val_section_name = val.section_name;

                    // check if the previous ul is opened then close it and reset the variable
                    if (bln_ul_opened === true) {
                      _str_html += "</ul>";
                      bln_ul_opened = false;
                    }
                  }

                  // check if ul is not opened then open it and bind the section name and reset the variable
                  if (bln_ul_opened === false) {
                    _str_html +=
                      "<h6 class='fw-bold' key='" +
                      index +
                      "'>" +
                      val.section_name +
                      "</h6>";
                    _str_html += "<ul class='mb-4'>";
                    bln_ul_opened = true;
                  }

                  // bind the li node for the result
                  _str_html += "<li key='" + index + "'>";
                  _str_html +=
                    "<a href='"+ globalVariables.variables.strDomainUrl + val.link + "'>" + val.result + "</a>";
                  _str_html += "</li>";
                })
              : null}

            <>{Parser(_str_html)}</>
          </div>
        </Container>
      </div>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Search Results</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}
