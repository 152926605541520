import React from "react";
import { useState,useEffect} from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import { GetPublicationsList } from "../../api/publications-functions";
import { GetPublicationDistinctYearList } from "../../api/publication-distinct-year-functions";
import Metatag from "../../hooks/Metatag";
import {useNavigate, useParams } from "react-router-dom";
import PublicationYearWiseFilter from "../../common/PublicationYearWiseFilter";
import axios from "axios";
import globalVariables from "../../global-variables";
import { Link } from "react-router-dom";

export default function Publications() {
    
    let { pub_year } = useParams();
	const qs_pub_year = pub_year;

    const [publication, setPublicationList] = useState(null);
    const [publicationyear, setPublicationYearList] = useState([]);

	const navigate =  useNavigate();

     // success function when the list is retrived
     const OnGetPublicationSuccess = (p_response) => {
    
        // set the list for looping
        setPublicationList(p_response);
    }

    useEffect(()=>{
    
            setTimeout(() => {
    
                //call the function to get the publication
                    // make the object for getting the publicaiton list
                    let _objPublication = new Object();
                    _objPublication.pub_status = 1;
                    // _objPublication._selected_year = qs_pub_year;

                    // call the function to get the roadblock
                    GetPublicationsList(_objPublication, OnGetPublicationSuccess,navigate);
    
            }, 100);

    },[qs_pub_year])

    useEffect(()=>{
        setTimeout(() => {
            // set year wise value for the dropdown 
            if (qs_pub_year != undefined && qs_pub_year != null && qs_pub_year != "") {

                document.getElementById("ddlPublicationYear").value = qs_pub_year;
            }
        }, 100);
    },[publication])

      // things to be done when the page is loaded
      useEffect(() => {
         //make the object for getting the news type year list
         let _objPublicationYear = new Object();
         _objPublicationYear.pub_status = 1;

       //Get pub type yesr List
       axios.post(globalVariables.variables.strApiUrl + "/publicationsApi/getpublicationsdistinctyearForWebadmin", _objPublicationYear)
       .then((response_list) => {

           if(response_list.data === "error")
           {
               navigate("/error-page")
           }
           else
           {
               // pass the result in the success function of the respective page
               setPublicationYearList(response_list.data[0]);
               
           }
           
       });

   }, []);
    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="Publications at CMinds IIT Bombay | AI-ML Publications"
                     keywords="Publications at CMinds IIT Bombay, AI-ML Publications, Artificial Intelligence Publications, Machine Learning Publications, Data Science Publications"
                     description="Artificial Intelligence, Machine Learning, Data Science Publications at CMinds IITB."/>
            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-research.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Current Publications</h1>
                        <div className="ms-4 wow fadeInUp d-none">
                            <PublicationYearWiseFilter yearList={publicationyear} _id={"ddlPublicationYear"} navigate_url={"publications"}/>
                        </div>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            {
                publication !=null ?

                    publication.length > 0
                    ?
                        <div className="container-fluid px-0 my-md-5 publication-content">
                            <Container className="py-lg-5">
                                <div className="border-1 border-primary p-lg-5 py-4 publications-table table-responsive position-relative">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="table-secondary">
                                                <th>Title of Paper/Article/Reference</th>
                                                <th>Faculty Name/<br />Parent Department</th>
                                                <th>Authors</th>
                                                {/* <th>Citations</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                publication.map((val,index)=>{
                                                    return(
                                                            <tr key={index}>
                                                              <td className="publication-details">
                                                                <Link to={global.variables.strDomainUrl + "publication-details/" + val.pub_pagename}> 
                                                                {val.pub_topaper}<br/>
                                                                </Link>
                                                                <b>Reference : </b> {val.pub_reference}
                                                              </td>
                                                              <td>{val.pub_fac_name}<br/>{val.pub_dep}</td> 
                                                              <td>{val.pub_authers}</td>
                                                              {/* <td>{val.pub_citations}</td> */}
                                                            </tr>
                                                        )
                                                })
                                            }
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </Container>
                        </div>
                    :
                        <div className="container-fluid px-0 my-md-5 my-4">
                            <Container >
                                <Row>
                                    <span className="text-center">No Records Found</span>
                                </Row>
                            </Container>
                        </div>
            :
            <div className="container-fluid px-0 my-md-5 my-4">
                <Container >
                    <Row>
                        <span className="text-center">Loading...</span>
                    </Row>
                </Container>
            </div>
            }
            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl+"research"}>Research</Breadcrumb.Item>
                <Breadcrumb.Item active>Publications</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};