import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";

import { Link , useNavigate } from "react-router-dom";
import axios from "axios";
import Metatag from "../../hooks/Metatag";
import {useParams } from "react-router-dom";
import ResDefaultImg from '../../assets/default_img/research-default.jpg';
import WOW from 'wowjs'

export default function ResearchProject() {

    const [researchcat, setResearchCategoryList] = useState([]);

    const [research, setResearchList] = useState([]);

    let {rcategory_id} = useParams();
    const qs_rcategory_id = rcategory_id;

    const navigate =  useNavigate();


    useEffect(() => {
        let _obj = new Object();
        _obj.rcategory_id = qs_rcategory_id;
        //get research category list
        axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList',_obj)
            .then((response_rcategory) => {

                if(response_rcategory.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    if(response_rcategory != undefined)
                    {
                        setResearchCategoryList(response_rcategory.data[0]);
                    }
                }
                
             }) 
    }, [qs_rcategory_id])

    //research categoty based project list
    useEffect(() => {
        let _obj = new Object();
        _obj.res_rcategory_id = qs_rcategory_id;
        _obj.res_status = 1;
        //get research category list
        axios.post(global.variables.strApiUrl + '/researchApi/getresearchList',_obj)
            .then((response_research) => {
                if(response_research.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setResearchList(response_research.data[0]);
                }
                
             }) 
    }, [qs_rcategory_id])

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, [research]);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            {
                researchcat[0] != undefined ?
                <Metatag title={researchcat[0]?.rcategory_pagetitle}
                         keywords={researchcat[0]?.rcategory_meta_keywords}
                         description={researchcat[0]?.rcategory_meta_description}/>
                :
                null
            }
            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-research.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">Research</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid px-0 my-md-5 py-4">
                {/* body header */}
                <Container>
                    {
                        researchcat?.map((val)=>{

                            return(
                                <div className="mb-5 text-center" key={val.rcategory_id}>
                                    <div className="hd1 text-center mb-3">
                                        <h2 className="fw-bolder h1 m-0 wow fadeInUp">{val.rcategory_name}</h2>
                                    </div>
                                    {
                                        val.rcategory_writeup != null && val.rcategory_writeup != "" ? 
                                        <div className="col-lg-12 col-xl-10 m-auto wow fadeInUp"> <span dangerouslySetInnerHTML={{ __html: val.rcategory_writeup }}></span></div>
                                        : null
                                    }
                                    
                                </div> 
                            )
                        })
                    }
                </Container>
               
                {
                    research != null ?
                        research.length > 0 ? 

                            <>
                                {
                                    research?.map((val,index)=>{
                                        return(
                                            <>
                                                 {
                                                    // (++index) % 2 != 0 ?
                                                    <Container className="py-md-4 py-4" key={index}>
                                                            <div className="border-box-2">
                                                                <Row className="px-md-3">
                                                                        <Col className="col-lg-8 col-xl-9 col-md-7 col-12 order-xl-2 order-2">
                                                                            <div className="h-100 py-lg-4 py-md-4">
                                                                                <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                                                                    <div className="px-lg-4 px-3 d-flex flex-column h-100">
                                                                                        <h5 className="text-primary wow fadeInUp">{val.res_pro_name}</h5>
                                                                                        {
                                                                                            val.res_fac_members != null && val.res_fac_members != "" ? 
                                                                                            <p className="wow fadeInUp"><span dangerouslySetInnerHTML={{ __html: val.res_fac_members }}></span></p>
                                                                                            : null
                                                                                        }
                                                                                        
                                                                                        <div className="pt-3 mt-auto wow fadeInUp">
                                                                                            <Link to={global.variables.strDomainUrl +  "research-details/" + val.res_pagename} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="col-lg-4 col-xl-3 col-md-5 col-12 text-end z-index-2 order-xl-1 order-1 px-lg-0">
                                                                            <Link to={global.variables.strDomainUrl +  "research-details/" + val.res_pagename} className="shiny position-relative overflow-hidden my-lg-4 my-md-4 mt-0 mb-3 d-block">
                                                                                <div className="bg-pic m-0 d-block border">
                                                                                {
                                                                                    val.res_thu_img != null && val.res_thu_img != "" ? 
                                                                                    <img src={global.variables.strApiUrl + "/research-thumbnail/" + val.res_thu_img} className="img-fluid wow fadeIn m-0" alt={val.res_pro_name} title={val.res_pro_name} />

                                                                                    ://  default image 
                                                                                    <img src={ResDefaultImg} className="img-fluid wow fadeIn m-0" alt={val.res_pro_name} title={val.res_pro_name} />
                                                                                    
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                </Row>
                                                            </div>
                                                    </Container>
                                                    // :
                                                    // <Container className="py-md-4 py-4" key={index}>
                                                    //         <div className="border-box-1">
                                                    //             <Row className="px-md-3">
                                                    //                     <Col className="col-lg-8 col-xl-9 col-md-7 col-12 order-md-1 order-2">
                                                    //                         <div className="h-100 py-lg-4 py-md-4">
                                                    //                             <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                                    //                                 <div className="px-lg-4 px-3 d-flex flex-column h-100">
                                                    //                                     <h5 className="text-primary wow fadeInUp">{val.res_pro_name}</h5>
                                                    //                                     {
                                                    //                                         val.res_fac_members != null && val.res_fac_members != "" ? 
                                                    //                                         <p className="wow fadeInUp"><span dangerouslySetInnerHTML={{ __html: val.res_fac_members }}></span></p>
                                                    //                                         : null
                                                    //                                     }
                                                                                        
                                                    //                                     <div className="pt-3 mt-auto wow fadeInUp">
                                                    //                                         <Link to={global.variables.strDomainUrl +  "research-details/" + val.res_pagename} className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
                                                    //                                     </div>
                                                    //                                 </div>
                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </Col>
                                                    //                     <Col className="col-lg-4 col-xl-3 col-md-5 col-12 text-end z-index-2 order-md-2 order-1 px-lg-0">
                                                    //                         <Link to={global.variables.strDomainUrl +  "research-details/" + val.res_pagename} className="shiny position-relative overflow-hidden my-lg-4 my-md-4 mt-0 mb-3 d-block">
                                                    //                             <div className="bg-pic m-0 d-block">
                                                    //                             {
                                                    //                                 val.res_thu_img != null && val.res_thu_img != "" ? 
                                                    //                                 <img src={global.variables.strApiUrl + "/research-thumbnail/" + val.res_thu_img} className="img-fluid wow fadeIn m-0" alt={val.res_pro_name} title={val.res_pro_name} />

                                                    //                                 ://  default image 
                                                    //                                 <img src={ResDefaultImg} className="img-fluid wow fadeIn m-0" alt={val.res_pro_name} title={val.res_pro_name} />
                                                                                    
                                                    //                                 }
                                                    //                             </div>
                                                    //                         </Link>
                                                    //                     </Col>
                                                    //             </Row>
                                                    //         </div>
                                                    // </Container>
                                                }
                                            </>
                                           
                                            
                                        )
                                    })
                                } 
                              
                            </>
                        :null
                    :
                    <Container className="py-md-4 py-4">
                        <Row>
                            <span className="text-center">Loading...</span>
                        </Row>
                    </Container>

                }
            </div>

            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "research"}>Research</Breadcrumb.Item>
                {
                    researchcat != null?
                    <Breadcrumb.Item active>{researchcat[0]?.rcategory_name}</Breadcrumb.Item>
                    :
                    null
                }
                
            </Breadcrumb>
        </>
    );
};