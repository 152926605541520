import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../layout/header";
import { Container, Row, Col, Button } from 'reactstrap';
import globalVariables from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsLink45Deg, BsImages, BsFilePdf, BsPlayBtn } from "react-icons/bs";
import FsLightbox from "fslightbox-react";
import { GetNewsList } from "../../api/news-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import NewsDefaultImg from '../../assets/default_img/news-default.png';

import Metatag from "../../hooks/Metatag";
export default function AdmissionDetails() {

    //Getting id from url
    let { admission_page_name } = useParams();
    const qs_admission_page_name = admission_page_name;

    // constant to be used on the page
    const [news, setNewsList] = useState(null);

    const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
    const [news_gallery, setNewsGallery] = useState([]);

    const [videos, setvideosToggler] = useState(false);
    const [lightbox_video, setLightBoxVideo] = useState([]);


    const [gallery_key, setGalleryKey] = useState("");

    const navigate = useNavigate();

    // success function when the list is retrived
    const OnGetNewsSuccess = (p_response) => {

        // set the list for looping
        setNewsList(p_response);
    }

    // success function when news gallery is retrived
    const OnGetNewsGallerySuccess = (p_response) => {

        let _arrGallery = [];

        // loop and make the array for binding the gallery
        for (let i = 0; i < p_response.length; i++) {
            _arrGallery.push(globalVariables.variables.strApiUrl + "/news-gallery-image/" + p_response[i].gallery_pic);
        }

        // set the array in the constant
        setNewsGallery(_arrGallery);

        setGalleryKey(Date.now());

        // using time out for the value to get saved in state 
        setTimeout(() => {
            // trigger the link click to display the news gallery popup
            document.getElementById("lnk_trigger_news_gallery_popup_news").click();
        }, 200);

    }

    // function to invoke photo gallery
    const InvokePhotoGallery = (e, p_news_id) => {

        e.preventDefault();

        // Make an object for getting news gallery
        let _objNewsGallery = new Object();
        _objNewsGallery.gallery_status = 1;
        _objNewsGallery.gallery_news_id = p_news_id;

        // call the function to get the news gallery
        GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);

    };

    // function to invoke video popup
    const InvokeVideoPopup = (e, p_news_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_news_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_news").click();

    };

    // things to be done when the page is loaded
    useEffect(() => {

        // make the object for getting the visual panel list
        let _objNews = new Object();
        _objNews._news_pagename = qs_admission_page_name;
        _objNews._news_status = 1;
        _objNews._news_type_id = globalVariables.variables.strNewsTypeForAdmission_id;
        // call the function to get the roadblock
        GetNewsList(_objNews, OnGetNewsSuccess, navigate);

    }, []);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-news.jpg')" }}>
                    <img src={globalVariables.variables.strDomainUrl + "images/masthead-admission.jpg"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Admissions</h1>
                    </Container>
                </div>
            </div>

            {

                news != null ?

                    news.length > 0 ?
                        <div className="container-fluid px-0 my-md-5 my-4">
                            {
                                news[0] != undefined && news[0] != null ?
                                    <Metatag title={news[0]?.news_pagetitle} keywords={news[0]?.news_meta_keywords} description={news[0]?.news_meta_description} />
                                    :
                                    null

                            }
                            <Container className="py-xl-5 admision-container">
                                <div className="border px-md-4 py-md-5 p-3">
                                    <div className="px-lg-3 d-md-flex">
                                        {
                                            news[0].news_images != null && news[0].news_images != "null" && news[0].news_images != "" ?
                                                <div className="pe-5" style={{ minWidth: 280 + "px" }}>
                                                    <div className="h-100">
                                                        <img
                                                            src={
                                                                globalVariables.variables.strApiUrl + "/news-main-image/" + news[0].news_images
                                                            }
                                                            className="img-fluid wow fadeInUp border"
                                                            alt={news[0].news_headline}
                                                            title={news[0].news_headline}
                                                        />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <div className="flex-grow-1">
                                            <div className="h-100">
                                                <div className="position-relative">
                                                    <h5 className="fw-semibold lh-sm wow fadeInUp">
                                                        {news[0].news_headline}
                                                    </h5>

                                                    <div className="mb-3 wow fadeInUp"><i className="mb-2 d-block">{news[0].news_date}</i></div>

                                                    {/* Sourse */}
                                                    {
                                                        news[0].news_sourse != null && news[0].news_sourse != "null" && news[0].news_sourse != "" ?
                                                            <div className="mb-3 wow fadeInUp">Source: {news[0].news_sourse}</div>
                                                            : null
                                                    }
                                                    <div className="mb-3 d-flex gap-3 wow fadeInUp">

                                                        {/* link */}
                                                        {
                                                            news[0].news_link != null && news[0].news_link != "null" && news[0].news_link != "" ?
                                                                <a href={news[0].news_link} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsLink45Deg className="h6 m-0" /></a>
                                                                :
                                                                null
                                                        }

                                                        {/* Photo Gallery */}
                                                        {
                                                            parseInt(news[0].news_gallery_count) > 0 ?
                                                                <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokePhotoGallery(event, news[0].news_id)}><BsImages className="h6 m-0" /></a>
                                                                :
                                                                null
                                                        }

                                                        {/* Document */}
                                                        {
                                                            news[0].news_document != null && news[0].news_document != "null" && news[0].news_document != "" ?
                                                                <a href={globalVariables.variables.strApiUrl + "/news-documents/" + news[0].news_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsFilePdf className="h6 m-0" /></a>
                                                                :
                                                                null
                                                        }

                                                        {/* Video */}
                                                        {
                                                            news[0].news_video_link != null && news[0].news_video_link != "null" && news[0].news_video_link != "" ?
                                                                <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokeVideoPopup(event, news[0].news_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                    {/* Caption */}
                                                    {
                                                        news[0].news_caption != null && news[0].news_caption != "null" && news[0].news_caption != "" ?
                                                            <div className="mb-3 wow fadeInUp"><i>{news[0].news_caption}</i></div>
                                                            : null
                                                    }

                                                    {/* Details */}
                                                    {
                                                        news[0].news_details != null && news[0].news_details != "null" && news[0].news_details != "" ?
                                                            <div className="mb-3 wow fadeInUp ck-content"><span dangerouslySetInnerHTML={{ __html: news[0].news_details }}></span></div>
                                                            : null
                                                    }


                                                    <div className="mt-5 wow fadeInUp">
                                                        <Button onClick={() => navigate(-1)} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                                            Back
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>

                            {/* Video popup */}
                            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
                            <a onClick={(e) => {
                                e.preventDefault();
                                setvideosToggler(!videos);
                            }}
                                className="d-none"
                                id="lnk_trigger_video_popup_news"></a>

                            <FsLightbox
                                toggler={videos}
                                sources={lightbox_video}
                            />

                            {/* News Gallery popup */}
                            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
                            <a onClick={(e) => {
                                e.preventDefault();
                                setNewsGalleryToggler(!news_gallery_toggler);
                            }}
                                className="d-none"
                                id="lnk_trigger_news_gallery_popup_news"></a>

                            <FsLightbox
                                toggler={news_gallery_toggler}
                                sources={news_gallery}
                                key={gallery_key}
                            />

                        </div>
                        :
                        <div className="container-fluid px-0 my-md-5 my-4">
                            <Container className="py-xl-5">
                                <div className="border-1">
                                    <Row className="px-lg-3">

                                        <Col className="col-12 col-lg-6 col-xl-8  order-lg-1">
                                            <div className="h-100 py-4 py-md-5">
                                                <div className="px-md-5 position-relative">
                                                    <div className="mb-3">
                                                        <h5 className="fw-semibold lh-sm wow fadeInUp">
                                                            The resource you are looking for does not exists or has been removed by the administrator.
                                                        </h5>
                                                    </div>

                                                    <div className="mt-5 wow fadeInUp">
                                                        <Button onClick={() => navigate(-1)} className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">
                                                            Back
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </Container>

                        </div>

                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                       {/* <Container > */}
                            <Row>
                                <span className="text-center">Loading...</span>
                            </Row>
                        {/* </Container> */}
                    </div>

            }


            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={globalVariables.variables.strDomainUrl +"academics"}>Academics</Breadcrumb.Item>
                <Breadcrumb.Item href={globalVariables.variables.strDomainUrl +"admissions"}>Admissions</Breadcrumb.Item>
                {
                    news != null ?
                        <Breadcrumb.Item active>{news[0]?.news_headline}</Breadcrumb.Item>
                        :
                        null
                }

            </Breadcrumb>
        </>
    );
};