import { EventDisYearApiUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";

export const GetEventTypeDistinctYearList = (p_request_object, p_success_function,navigate) => {
	
    //Get event type yesr List
	axios.post(globalVariables.variables.strApiUrl + EventDisYearApiUrls.get_event_disyear_api, p_request_object)
	.then((response_list) => {

		if(response_list.data === "error")
        {
             navigate("/error-page")
        }
        else
        {
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
			
		}
        
	});

};