import React from 'react'

import { Helmet } from 'react-helmet';

export default function Metatag(props) {

            
    const title = props.title
    const keywords = props.keywords
    const description = props.description
    const errorPage = props.error
    
   
  return (
    <>
    {/* implementation for head tag  */}
        <Helmet>
            <title>{title}</title>  
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <link rel='canonical' href={props.url} />
            <meta name='robots' content={props.robots} />

          {
            errorPage === "1" ?
            <meta name="robots" content="noindex, follow"/>
            :
            null
          }
        </Helmet>
    </>
  )
}
