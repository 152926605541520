import React, { useState, useEffect } from "react";
import { GetNewsList } from "../../api/news-functions";
import { GetNewsTypeDistinctYearList } from "../../api/new-type-distinct-year-functions";
import globalVariables from "../../global-variables";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight, BsLink45Deg, BsImages, BsFilePdf, BsPlayBtn } from "react-icons/bs";
import { Link, useNavigate , useParams} from "react-router-dom";
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import NewsDefaultImg from '../../assets/default_img/news-default.png';

import Metatag from "../../hooks/Metatag";
import NewsYearWiseFilter from "../../common/NewsYearWiseFilter";
import axios from "axios";
export default function News() {

    let { news_year } = useParams();
	const qs_news_year = news_year;

    // constant to be used on the page
    const [news, setNewsList] = useState(null);
   
    const [newstypeyear, setNewsTypeYearList] = useState([]);
    const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
    const [news_gallery, setNewsGallery] = useState([]);
    const [news_gallery_titel, setNewsGalleryTitel] = useState([]);

    const [gallery_key, setGalleryKey] = useState("");

    const [videos, setvideosToggler] = useState(false);
    const [lightbox_video, setLightBoxVideo] = useState([]);

    const navigate =  useNavigate();

    // success function when the list is retrived
    const OnGetNewsSuccess = (p_response) => {

        // set the list for looping
        setNewsList(p_response);
    }

    useEffect(()=>{

        setTimeout(() => {

            //call the function to get the news
            // make the object for getting the news list
            let _objNews = new Object();
            _objNews._news_type_id = globalVariables.variables.strNewsTypeForNews_id;
            _objNews._news_status = 1;
            _objNews._selected_year = qs_news_year;

            // call the function to get the news list
            GetNewsList(_objNews, OnGetNewsSuccess,navigate);

        }, 100);

    },[qs_news_year])

    useEffect(()=>{
        setTimeout(() => {
             // set year wise value for the dropdown 
             if (qs_news_year != undefined && qs_news_year != null && qs_news_year != "") {

                document.getElementById("ddlNewsYear").value = qs_news_year;
            }
        }, 100);
   },[news])

    // success function when news gallery is retrived
    const OnGetNewsGallerySuccess = (p_response) => {

        let _arrGallery = [];

        let _arrGalleryTitel = [];

        // loop and make the array for binding the gallery
        for (let i = 0; i < p_response.length; i++) {
            _arrGallery.push(globalVariables.variables.strApiUrl + "/news-gallery-image/" + p_response[i].gallery_pic);
            _arrGalleryTitel.push(p_response[i].gallery_title);
        }

        // set the array in the constant
        setNewsGallery(_arrGallery);

        setGalleryKey(Date.now());

        setNewsGalleryTitel(_arrGalleryTitel);

        // using time out for the value to get saved in state 
        setTimeout(() => {
            // trigger the link click to display the news gallery popup
            document.getElementById("lnk_trigger_news_gallery_popup_home").click();
        }, 200);

    }

    // function to invoke photo gallery
    const InvokePhotoGallery = (e, p_news_id) => {

        e.preventDefault();

        // Make an object for getting news gallery
        let _objNewsGallery = new Object();
        _objNewsGallery.gallery_status = 1;
        _objNewsGallery.gallery_news_id = p_news_id;

        // call the function to get the news gallery
        GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess,navigate);
    };

    // function to invoke video popup
    const InvokeVideoPopup = (e, p_news_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_news_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_home").click();

    };

    // things to be done when the page is loaded
    useEffect(() => {
        //make the object for getting the news type year list
        let _objNewsTypeYear = new Object();
        _objNewsTypeYear._news_type_id = globalVariables.variables.strNewsTypeForNews_id;

        //Get news type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/newsApi/getnewstypedistinctyear", _objNewsTypeYear)
        .then((response_list) => {

            if(response_list.data === "error")
            {
                navigate("/error-page")
            }
            else
            {
                // pass the result in the success function of the respective page
                setNewsTypeYearList(response_list.data[0]);
                
            }
            
        });

    }, []);
  

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="News at CMinds IIT Bombay | AI-ML News"
                     keywords="News at CMinds IIT Bombay, AI-ML News, Artificial Intelligence News, Machine Learning News, Data Science News"
                     description="Artificial Intelligence, Machine Learning, Data Science News at CMinds IITB."/>

            <div className="container-flstrDomainUrlapiuid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-news.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">News Updates</h1>
                        <div className="ms-4 wow fadeInUp">
                            <NewsYearWiseFilter yearList={newstypeyear} _id={"ddlNewsYear"} navigate_url={"news"}/>
                        </div>
                    </Container>
                </div>
            </div>

                {
                    news != null ?

                        news.length > 0 ?
                        <div className="container-fluid px-0 my-md-5 my-4">
                            <Container className="py-xl-5">
                                <Row className="row-cols-1 row-cols-xl-2 g-4 g-lg-5">
                                    {
                                        news.map((val) => {

                                            return (
                                                <Col className="news" key={val.news_id}>
                                                    <div className="d-md-flex bg-light position-relative overflow-hidden h-100 wow fadeInUp">

                                                        {
                                                            val.news_images != null && val.news_images != "null" && val.news_images != "" ?
                                                                <div className="pic d-flex justify-content-center wow fadeInUp">
                                                                    <Link to={ globalVariables.variables.strDomainUrl + "news-details/" + val.news_pagename} className="nav-link">
                                                                        <img
                                                                            src={
                                                                                globalVariables.variables.strApiUrl + "/news-main-image/" + val.news_images
                                                                            }
                                                                            className="img-fluid"
                                                                            alt={val.news_headline}
                                                                            title={val.news_headline}
                                                                        />
                                                                    </Link>
                                                                </div>

                                                                :
                                                                <div className="pic d-flex justify-content-center wow fadeInUp">
                                                                    <Link to={globalVariables.variables.strDomainUrl  + "news-details/" + val.news_pagename} className="nav-link">
                                                                        <img
                                                                            src={NewsDefaultImg}
                                                                            className="img-fluid"
                                                                            alt="default image"
                                                                            title="default image"
                                                                        />
                                                                    </Link>
                                                                </div>
                                                        }


                                                        <div className="d-flex justify-content-center flex-grow-1 wow fadeInUp">
                                                            <div className="px-4 py-md-3 py-4 w-100">
                                                                <Link to={"/news-details/" + val.news_pagename} className="nav-link">
                                                                    <h5 className="fw-bold lh-sm">
                                                                        {val.news_headline}
                                                                    </h5>
                                                                </Link>
                                                                <i className="mb-2">{val.news_date}</i>
                                                                <div className="d-flex gap-3 mt-2">

                                                                    {/* link */}
                                                                    {
                                                                        val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                                                            <a href={val.news_link} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsLink45Deg className="h6 m-0" /></a>
                                                                            :
                                                                            null
                                                                    }

                                                                    {/* Photo Gallery */}
                                                                    {
                                                                        parseInt(val.news_gallery_count) > 0 ?
                                                                            <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokePhotoGallery(event, val.news_id)}><BsImages className="h6 m-0" /></a>
                                                                            :
                                                                            null
                                                                    }

                                                                    {/* Document */}
                                                                    {
                                                                        val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                                                            <a href={globalVariables.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsFilePdf className="h6 m-0" /></a>
                                                                            :
                                                                            null
                                                                    }

                                                                    {/* Video */}
                                                                    {
                                                                        val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                                                            <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokeVideoPopup(event, val.news_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <Link
                                                                to={"/news-details/" + val.news_pagename}
                                                                className="btnlink bg-primary d-flex align-items-center justify-content-center wow fadeInUp"
                                                            >
                                                                <BsChevronRight />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )

                                        })

                                    }
                                </Row>
                            </Container>
                        </div>
                        :
                        <div className="container-fluid px-0 my-md-5 my-4">
                            <Container >
                                <Row>
                                    <span className="text-center">News updates will be posted soon</span>
                                </Row>
                            </Container>
                        </div>
                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container >
                            <Row>
                                <span className="text-center">Loading...</span>
                            </Row>
                        </Container>
                    </div>
            }

            {/* Video popup */}
            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setvideosToggler(!videos);
            }}
                className="d-none"
                id="lnk_trigger_video_popup_home"></a>

            <FsLightbox
                toggler={videos}
                sources={lightbox_video}
            />

            {/* News Gallery popup */}
            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setNewsGalleryToggler(!news_gallery_toggler);
            }}
                className="d-none"
                id="lnk_trigger_news_gallery_popup_home"></a>

            <FsLightbox
                toggler={news_gallery_toggler}
                sources={news_gallery}
                key={gallery_key}
            />


            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
                <Breadcrumb.Item active>News Updates</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};