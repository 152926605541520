
import { RoadblockApiUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";

export const GetRoadblockList = (p_success_function,navigate) => {

   
    // create object for getting the road block
    let _objRoadBlock = new Object();
    _objRoadBlock.roadblock_id = globalVariables.variables.strRoadBlock_id;

    //Get road block List
	axios.post(globalVariables.variables.strApiUrl + RoadblockApiUrls.get_roadblock_api, _objRoadBlock)
	.then((response_list) => {
        // alert(JSON.stringify(response_list.data))
        if(response_list.data === "error")
        { 
            navigate("/error-page")
        }
        else
        {
            p_success_function(response_list.data[0]);
        }
        // pass the result in the success function of the respective page
		
	});

};