import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";

// import "./bootstrap/dist/css/bootstrap.min.css"
import "./assets/bootstrap/bootstrap.min.css"
import "./assets/css/ds.css"
import "./assets/css/animate.min.css"

import axios  from 'axios';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </React.Fragment>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
