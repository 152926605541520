import React, { useState, useEffect } from "react";
import Header from "../layout/header";
import { Container, Row, Col } from "reactstrap";
import global from "../../global-variables";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  BsChevronRight,
  BsLink45Deg,
  BsImages,
  BsFilePdf,
  BsPlayBtn,
  BsThreeDots,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { GetNewsList } from "../../api/news-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import globalVariables from "../../global-variables";
import $ from "jquery";
import Metatag from "../../hooks/Metatag";
import { Link } from "react-router-dom";

export default function Admission() {
  const [videos, setvideosToggler] = useState(false);
  const [lightbox_video, setLightBoxVideo] = useState([]);

  // constant to be used on the page
  const [news_ticker, setNewsTickerList] = useState([]);

  const [data_loading, setDataLoading] = useState(false);

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);

  const navigate = useNavigate();

  // success function when the news is retrived
  const OnGetNewsSuccess = (p_response) => {
    // set the list for looping
    setNewsTickerList(p_response);

    // set data loaded variable
    setDataLoading(true);
  };

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {
    let _arrGallery = [];

    // loop and make the array for binding the gallery
    for (let i = 0; i < p_response.length; i++) {
      _arrGallery.push(
        globalVariables.variables.strApiUrl +
          "/news-gallery-image/" +
          p_response[i].gallery_pic
      );
    }

    // set the array in the constant
    setNewsGallery(_arrGallery);

    // using time out for the value to get saved in state
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document
        .getElementById("lnk_trigger_news_gallery_popup_admission")
        .click();
    }, 200);
  };

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_admission_video_url) => {
    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_admission_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_admission").click();
  };

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_admission_id) => {
    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_admission_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);
  };

  // things to be done when the page is loaded
  useEffect(() => {
    // make the object for getting the visual panel list
    let _objNews = new Object();
    _objNews._news_id = null;
    _objNews._news_type_id =
      globalVariables.variables.strNewsTypeForAdmission_id;
    _objNews._news_date = null;
    _objNews._news_is_on_home = null;
    _objNews._news_status = 1;

    // call the function to get the roadblock
    GetNewsList(_objNews, OnGetNewsSuccess, navigate);
  }, []);

  return (
    <>
      <Header main_class="bg-header-home"></Header>
      <Metatag
        title="Admission Notices of C-MInDS at IIT Bombay | Admission for AI and ML Academic Programs at IIT-B"
        keywords="Admission Notices of C-MInDS at IIT Bombay, Admission for AI and ML Academic Programs at IIT-B"
        description="Admission Notices and Announces of C-MInDS at IIT Bombay"
      />

      {/* Visual Container */}
      <div className="container-fluid visual-container-1920 px-0">
        <div
          className="bg-visual-inside position-relative"
          style={{ backgroundImage: "url('./images/masthead-admission.jpg')" }}
        >
          <img
            src={global.variables.strDomainUrl + "images/masthead-spacer.png"}
            className="img-fluid"
            alt=""
            title=""
          />
          <Container className="visual-text mx-auto">
            <h1 className="fw-semibold wow fadeInUp">Admissions</h1>
          </Container>
        </div>
      </div>

      {data_loading ? (
        <>
          {/* Body Panel */}
          <div className="container-fluid px-0 my-md-5">
            <Container className="py-lg-5">
              <div className="border-1 border-primary p-lg-5 p-md-4 py-4 admission-update-list">
                {news_ticker.length > 0 ? (
                  news_ticker.map((val) => {
                    return (
                      <ul
                        className="list-group list-group-horizontal-md wow fadeInUp"
                        key={val.news_id}
                      >
                        <li className="list-group-item">{val.news_date}</li>
                        {/* check if there is link or any details is present then give detail page link else normal text  */}
                        {(val.news_link != null && val.news_link != "") ||
                          parseInt(val.news_gallery_count) > 0 ||
                          (val.news_document != null && val.news_document != "") ||
                          (val.news_video_link != null && val.news_video_link != "") ||
                         (val.news_details !== null && val.news_details !== "" )
                         ?
                        <li className="list-group-item flex-grow-1">
                          <Link
                            to={`${globalVariables.variables.strDomainUrl}admissions/${val.news_pagename}`}
                          >
                            {val.news_headline}
                          </Link>
                        </li>
                        :
                        <li className="list-group-item flex-grow-1">
                            {val.news_headline}
                        </li>
                        }
                        <li className="list-group-item">
                          <div className="d-flex gap-3  justify-content-md-end">
                            {/* link */}
                            {val.news_link != null &&
                            val.news_link != "null" &&
                            val.news_link != "" ? (
                              <a
                                href={val.news_link}
                                target="_blank"
                                className="btn btn-sm btn-outline-primary rounded-0"
                              >
                                <BsLink45Deg className="h6 m-0" />
                              </a>
                            ) : null}

                            {/* Photo Gallery */}
                            {parseInt(val.news_gallery_count) > 0 ? (
                              <a
                                className="btn btn-sm btn-outline-primary rounded-0"
                                onClick={(event) =>
                                  InvokePhotoGallery(event, val.news_id)
                                }
                              >
                                <BsImages className="h6 m-0" />
                              </a>
                            ) : null}

                            {/* Document */}
                            {val.news_document != null &&
                            val.news_document != "null" &&
                            val.news_document != "" ? (
                              <a
                                href={
                                  globalVariables.variables.strApiUrl +
                                  "/news-documents/" +
                                  val.news_document
                                }
                                target="_blank"
                                className="btn btn-sm btn-outline-primary rounded-0"
                              >
                                <BsFilePdf className="h6 m-0" />
                              </a>
                            ) : null}

                            {/* Video Popup */}
                            {val.news_video_link != null &&
                            val.news_video_link != "null" &&
                            val.news_video_link != "" ? (
                              <a
                                className="btn btn-sm btn-outline-primary rounded-0"
                                onClick={(event) =>
                                  InvokeVideoPopup(event, val.news_video_link)
                                }
                              >
                                <BsPlayBtn className="h6 m-0" />
                              </a>
                            ) : null}
                            {val.news_details !== null &&
                            val.news_details !== "" ? (
                              <Link
                                to={`${globalVariables.variables.strDomainUrl}admissions/${val.news_pagename}`}
                                className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                              >
                                <BsThreeDots className="h6 m-0" />
                              </Link>
                            ) : null}
                          </div>
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  <div className="text-center wow fadeInUp">
                    <b>Admission News updates will be posted soon</b>
                  </div>
                )}
              </div>
            </Container>
          </div>
        </>
      ) : (
        <div className="container-fluid px-0 my-md-5">
          <Row>
            <span className="text-center">Loading...</span>
          </Row>
        </div>
      )}

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item href={global.variables.strDomainUrl + "academics"}>
          Academics
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Admissions</Breadcrumb.Item>
      </Breadcrumb>

      {/* Video popup */}
      {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
      <a
        onClick={(e) => {
          e.preventDefault();
          setvideosToggler(!videos);
        }}
        className="d-none"
        id="lnk_trigger_video_popup_admission"
      ></a>

      <FsLightbox toggler={videos} sources={lightbox_video} />

      {/* News Gallery popup */}
      {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
      <a
        onClick={(e) => {
          e.preventDefault();
          setNewsGalleryToggler(!news_gallery_toggler);
        }}
        className="d-none"
        id="lnk_trigger_news_gallery_popup_admission"
      ></a>

      <FsLightbox toggler={news_gallery_toggler} sources={news_gallery} />
    </>
  );
}
