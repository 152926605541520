import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import PeopleDetails from '../layout/people-detailNav';
import { Container, Row, Col, AccordionItem, AccordionHeader } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link, useLocation,useParams, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { Faculty } from '../../common/data/people/faculty.js';
import globalVariables from '../../global-variables';
import { GetPeoples } from './GetPeoples';

import Metatag from "../../hooks/Metatag";
import $ from "jquery";


export default function People() {
    const [show, setShow] = useState(false);
    const [getfacultyid, setFacultyid] = useState('');

    let {pcategory_pagename} = useParams();
    const qs_pcategory_pagename = pcategory_pagename;

    const [peoplecategory, setPeopleCategory] = useState(null);

    const [peoplePerticularCategory,setPeoplePerticularCategory] = useState(null)
    
    const [PcategoryId,setpcategoryId] = useState();

    const [subcategory, SetSubcategoryList] = useState([]);
    const [peopleSubCategory_Id,setPeopleSubCategory_Id] = useState();

    const [blnHaspeople, setblnHaspeople] = useState(false);

    const [getpeoplelist, setPeopleList] = useState([]);

    const[getindex,setindex] = useState(null);

    const navigate =  useNavigate();
    
    useEffect(() => {
        //get peoplecategory list
        
        axios.post(global.variables.strApiUrl + '/peoplecategoryApi/getcategoryHavingPeopleList')
            .then((response_category) => {
                
                if(response_category.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setTimeout(() => { 
                        setPeopleCategory(response_category.data[0]);
                     }, 100)
                }
            });
    }, [])



    //People category list
    useEffect(()=>{
        let _obj = new Object();
        _obj.pcategory_status = 1;
        _obj.pcategory_pagename = qs_pcategory_pagename
       setTimeout(() => {
        if(qs_pcategory_pagename != null && qs_pcategory_pagename != '')
        {
            axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getpeopleCategoryList",_obj)
            .then((response_perticular_pCategory) => {
                if(response_perticular_pCategory.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                // pass the result in the success function of the respective page
                setPeoplePerticularCategory(response_perticular_pCategory.data[0]);
                setpcategoryId(response_perticular_pCategory.data[0][0]?.pcategory_id);
                }
            });
        }
       }, 200);
       

    },[qs_pcategory_pagename])
    
    useEffect(()=>{
        let _obj = new Object();
   
        _obj.ps_category_status = 1;
        _obj.pcategory_pagename = qs_pcategory_pagename;
        
           axios.post(global.variables.strApiUrl + '/peoplesubcategoryApi/getpeopleSubCategoryList', _obj)
               .then((response_subcategory) => {
                if(response_subcategory.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    //set true when people count grater than zero 
                    for (let i = 0; i < (Object.keys(response_subcategory.data[0]).length); i++) {
                    
                        if (response_subcategory.data[0][i].ps_people_count > 0) {
                            setblnHaspeople(true);
                        }
                    }
                    setTimeout(() => {
                        
                        SetSubcategoryList(response_subcategory.data[0]);
                        setPeopleSubCategory_Id(response_subcategory.data[0][0]?.ps_category_id)
                    }, 100);
                }
               });
              
    //set index
    setindex(0)

    },[qs_pcategory_pagename])

 

    useEffect(() => {
        let _obj = new Object();
        _obj.people_ps_category_id = peopleSubCategory_Id
        _obj.people_status = 1;
        
        axios.post(globalVariables.variables.strApiUrl + '/peopleApi/getpeopleList', _obj)
            .then((response_people) => {
                if(response_people.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    if(response_people.data[0][0]?.people_ps_category_id === peopleSubCategory_Id)
                    {
                        setTimeout(() => {
                            setPeopleList(response_people.data[0]);
                        }, 200);
                    }
                }

            });
    }, [peopleSubCategory_Id])
  

    const handleClose = () => setShow(false);
    const handleShow = (e, faculty_id) => {
        e.preventDefault();
        setShow(true);
        //get faculty id
        setFacultyid(faculty_id);
    }

    const [value, setValue] = React.useState(1);
    
    
    if (typeof window !== 'undefined') {
        window.addEventListener('popstate', () => {
            const params = new URLSearchParams(window.location.search)
            const newValue = params.get('tab')
            if (newValue) setValue(newValue)
        });
    };


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    //variable to close left navbar on link click
    const [expanded, setExpanded] = useState(false);

    //on tab click
    const handleclick = ()=>{
     var _class  =  document.getElementsByClassName("show");

     if(_class.length == 0)
     {

            $(document).ready(function() {
                window.location = window.location.href;
               
              });
     }
     else
     {
        $(document).ready(function() {
            window.location = window.location.href;
           
          });
    }
     
     
    }
    

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            { 
                peoplePerticularCategory != null && peoplePerticularCategory != undefined?
                <Metatag title={peoplePerticularCategory[0]?.pcategory_pagetitle} keywords={peoplePerticularCategory[0]?.pcategory_meta_keywords} description={peoplePerticularCategory [0]?.pcategory_meta_description}/>
                :
                null
            }

            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-people.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">People</h1>
                    </Container>
                </div>
            </div>

            <div className="container-fluid my-xl-5 my-4 px-0">
                {
                    peoplecategory != null ?
                        peoplecategory.length > 0 ?
                            <Container className="py-md-4">


                            {/* Category Tab */}
                        
                            <PeopleDetails submenutitle={peoplePerticularCategory != null && peoplePerticularCategory != undefined ? peoplePerticularCategory[0]?.pcategory_name : null}
                            peoplecategory={peoplecategory} tabclick={handleclick}  
                            qs_pcategory_pagename={qs_pcategory_pagename}></PeopleDetails>
                            
                           

                              {
                                peoplePerticularCategory != null ?
                                peoplePerticularCategory[0]?.psubcategory_count > 1 ?

                                    <Container className='people-content'>
                                        <Accordion defaultActiveKey="0" flush id='id_accordian'>
                                        {   
                                            subcategory != null ?
                                            blnHaspeople === true ?
                                            subcategory.length > 0 ?
                                            subcategory.map((val_subcategory,index)=>{
                                                
                                                const scrollToPosition = () => {
                                                    const position =  450 + index * 30; // Adjust the calculation based on your requirements
                                                    window.scrollTo({ top: position, left: 0, behavior: 'smooth' });
                                                };
                                                return(
                                                    <>
                                                        {
                                                            //if sub category having people than show name
                                                                val_subcategory.ps_people_count > 0 ?
                                                                
                                                                <Accordion.Item eventKey={index.toString()} key={val_subcategory.ps_category_id}>
                                                                
                                                                
                                                                <Accordion.Header  onClick={scrollToPosition}><h5 className='m-0'>{val_subcategory.ps_category_name}</h5></Accordion.Header>
                                                                    <Accordion.Body className='people'>
                                                                        <GetPeoples subcategory_id={val_subcategory.ps_category_id} />
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                :
                                                                <Accordion.Body className='people'>
                                                                        <GetPeoples subcategory_id={val_subcategory.ps_category_id} />
                                                                    </Accordion.Body>
                                                        }
                                                    </>
                                                
                                                )
                                            }): <span className="text-center">No records found</span>
                                            : <span className="text-center">Loading...</span>
                                        : <span className="text-center">No records found</span>
                                        }
                                        </Accordion>
                                    </Container>
                                :
                                <Container className='people-content'>
                                    {
                                        subcategory.map((val_subcategory,index)=>{
                                            return(
                                                <>
                                                    {
                                                    //if sub category having people than show name
                                                    val_subcategory.ps_people_count > 0 ?
                                                    
                                                    <GetPeoples subcategory_id={val_subcategory.ps_category_id} />
                                                    :
                                                    null

                                                }
                                                </>
                                       )
                                               })

                                    }
                                </Container>
                                : 
                                null
                              }
                                

                            </Container>
                        :
                        <Container className="my-5">
                            <Row>
                            <span className="text-center"><b>No records found</b></span>
                            </Row>
                        </Container>
                    :
                    <Container>
                        <Row>
                        <span className="text-center">Loading...</span>
                        </Row>
                    </Container>
                }
            </div>

            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>People</Breadcrumb.Item>

                { 
                    peoplePerticularCategory != null?
                    <Breadcrumb.Item active>{peoplePerticularCategory[0]?.pcategory_name}</Breadcrumb.Item>
                    :
                    null
                }
                
            </Breadcrumb>


            <Modal show={show} size="xl" centered onHide={handleClose}>
                <Modal.Header closeButton className="border-0 align-items-start">
                    {
                        Faculty.map((val) => {
                            return (
                                <>
                                    {
                                        val.faculty_id == getfacultyid ?
                                            <>
                                                <Row className='w-100 row-cols-1' key={val.faculty_id}>
                                                    <Col className="col-md-4 mb-3 mb-md-0">
                                                        <img src={global.variables.strDomainUrl + val.faculty_image} className="img-fluid w-100" alt="" title="" />
                                                    </Col>
                                                    <Col className="col-md-8">
                                                        <h3 className="fw-semibold lh-sm">{val.faculty_name}</h3>
                                                        <p className='fw-bold'>{val.faculty_designation}</p>

                                                        <p><b>Research Interests:</b> {val.faculty_research}</p>

                                                        {
                                                            //browser link
                                                            val.faculty_url != "" && val.faculty_url != null ?
                                                                <p><Link to={global.variables.strDomainUrl +  val.faculty_url} target={'_blank'} className="text-uppercase text-primary">Read More<BsChevronRight className="ms-2" /></Link> </p> :
                                                                null
                                                        }

                                                        {
                                                            //linkedin link
                                                            val.faculty_linkedin_url != "" && val.faculty_linkedin_url != null ?
                                                                <p><Link to={global.variables.strDomainUrl +  val.faculty_linkedin_url} target={'_blank'} className="text-uppercase text-primary">Read More<BsChevronRight className="ms-2" /></Link> </p> :
                                                                null
                                                        }

                                                    </Col>
                                                </Row>
                                            </> : null
                                    }

                                </>

                            )
                        })

                    }
                </Modal.Header>
            </Modal >

        </>
    );
};