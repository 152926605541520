import React from "react";
import { useState,useEffect } from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import { GetReportList } from "../../api/report-functions";
import { GetReportDistinctYearList } from "../../api/report-distinct-year-functions";
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate,useParams } from "react-router-dom";
import globalVariables from "../../global-variables";

import Metatag from "../../hooks/Metatag";
import ReportYearWiseFilter from "../../common/ReportYearWiseFilter";
import axios from "axios";

export default function Reports() {

    let { report_year } = useParams();
	const qs_report_year = report_year;

    // constant to be used on the page
    const [report, setReportList] = useState(null);

    const navigate =  useNavigate();

    const [reportyear, setReportYearList] = useState([]);
     // success function when the list is retrived
     const OnGetReportSuccess = (p_response) => {
        // set the list for looping
        setReportList(p_response);
    }

    useEffect(()=>{

        setTimeout(() => {

        //call the function to get the report
        // make the object for getting the report list
        let _objReport = new Object();
        _objReport.report_id = null;
        _objReport.report_status = 1;
        _objReport._selected_year = qs_report_year;
        // call the function to get the report list
        GetReportList(_objReport, OnGetReportSuccess,navigate);

        }, 100);

    },[qs_report_year])

    useEffect(()=>{
        setTimeout(() => {
            // set year wise value for the dropdown 
            if (qs_report_year != undefined && qs_report_year != null && qs_report_year != "") {

                document.getElementById("ddlReportYear").value = qs_report_year;
            }
        }, 100);
    },[report])

     // things to be done when the page is loaded
     useEffect(() => {
         //make the object for getting the report year list
         let _objReportYear = new Object();
         _objReportYear.report_id = null;
         _objReportYear.report_status = 1;

       //Get report type yesr List
       axios.post(globalVariables.variables.strApiUrl + "/reportApi/getreportdistinctyearForWebadmin", _objReportYear)
       .then((response_list) => {

           if(response_list.data === "error")
           {
               navigate("/error-page")
           }
           else
           {
               // pass the result in the success function of the respective page
               setReportYearList(response_list.data[0]);
               
           }
           
       });

   }, []);

    return (
        <>
            <Header main_class="bg-header-home"></Header>

            <Metatag title="C-MInDS Academic Reports | C-MInDS AI-ML Training Reports | C-Minds Quarterly Reports"
                     keywords="C-MInDS Academic Reports, C-MInDS AI-ML Training Reports, C-Minds Quarterly Reports" 
                     description="AI-ML Academic Reports and AI-ML Training Reports by Centre for Machine Intelligence and Data Science at IIT Bombay"/>
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-reports.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Reports</h1>
                        <div className="ms-4 wow fadeInUp">
                            <ReportYearWiseFilter yearList={reportyear} _id={"ddlReportYear"} navigate_url={"reports"}/>
                        
                        </div>
                    </Container>
                </div>
            </div>
            {
                report != null ?

                    report.length > 0 ?

                        <div className="container-fluid">
                            <Container className="py-5 reports">
                                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-4 g-xl-5 g-4">
                  
                                    {
                                        report.map((val)=>{
                                            return(
                                                <>
                                                    <Col>
                                                    <div className="border border-primary bg-light h-100 wow fadeInUp">
                                                            {
                                                                val.report_document != null && val.report_document != "" ?
                                                                    <Link className="py-xl-5 px-4 p-4 d-block"  to={globalVariables.variables.strApiUrl + "/reports-documents/" + val.report_document} target="_blank">
                                                                        <h2 className="h5 mb-1 wow fadeInUp">{val.report_title != null && val.report_title != "" ? val.report_title : null}</h2>
                                                                        <i className="text-dark-50 wow fadeInUp">{val.report_caption != null && val.report_caption != "" ? val.report_caption : null}</i>
                                                                    </Link>
                                                                        :
                                                                    <div className="py-xl-5 px-4 p-4 d-block">
                                                                       <h2 className="h5 mb-1 wow fadeInUp">{val.report_title != null && val.report_title != "" ? val.report_title : null}</h2>
                                                                        <i className="text-dark-50 wow fadeInUp">{val.report_caption != null && val.report_caption != "" ? val.report_caption : null}</i>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                </>
                                            )
                                            
                                              
                                        })
                                    }

                                </Row>
                            </Container>
                        </div>
                    :
                        <div className="container-fluid px-0 my-md-5 my-4">
                            <Container >
                                <Row>
                                    <span className="text-center">No Records Found</span>
                                </Row>
                            </Container>
                        </div>

                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container >
                            <Row>
                                <span className="text-center">Loading...</span>
                            </Row>
                        </Container>
                    </div>
            }
            
            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "about"}>About Us</Breadcrumb.Item>
                <Breadcrumb.Item active>Reports</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};