import {  React, useState, useEffect,useRef} from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Metatag from "../../hooks/Metatag";
import WOW from 'wowjs'
export default function Research() {

    
    const [researchcat, setResearchCategoryList] = useState([]);

    const [researchname, setresearchName] = useState(null);

    const navigate =  useNavigate();

    useEffect(() => {
        let _obj = new Object();
        _obj.rcategory_status = 1;
        //get research category list
        axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList',_obj)
            .then((response_rcategory) => {

                if(response_rcategory.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setResearchCategoryList(response_rcategory.data[0]);
                }
                
             }) 
    }, [])

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, [researchcat]);

    return (
        <>
            <Header main_class="bg-header-home"></Header>

            <Metatag title="AI and Machine Intelligence Research | Research at C-MInDS IITB"
                     keywords="AI and Machine Intelligence Research, Research at C-MInDS IIT B, Artificial Intelligence Research, Machine Learning Research, Research at C-MInDS IIT B, Artificial Intelligence Research Projects, Machine Learning Research Projects, AIML Research Projects"
                     description="Research at C-MInDS IIT Bombay is a cross-disciplinary initiative focused on AI and Machine Intelligence."/>

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-research.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">Research</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid px-0 mt-md-5 py-4">
                <Container>
                <div className="mb-5 text-center">
                        <div className="hd1 text-center mb-3">
                            <h2 className="fw-bolder h1 m-0 wow fadeInUp"><span>Research</span> Areas</h2>
                        </div>
                        <div className="col-lg-9 col-xl-8 m-auto wow fadeInUp">
                            <p>Research at C-MInDS is a cross-disciplinary initiative focused on AI and machine intelligence. The IIT Bombay ecosystem enables the Centre to work on real life problems which usually require multidisciplinary expertise. Currently, 75 faculty across the departments are associated with the Centre.</p>
                            <p className="m-0">A detailed highlight of research, both theoretical and applications-oriented, being carried out by different faculty members at IIT Bombay can be found in the following:</p>
                        </div>
                    </div>

                    {
                             researchcat != null ?
                             researchcat.length > 0 ?
                                <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 g-4 g-md-4 g-lg-4 justify-content-center">
                                    {
                                        researchcat.map((val)=>{

                                            return(
                                                <Col className="text-center" key={val.rcategory_id}>
                                                    <div className="btn-5 research w-100 h-100 p-3">

                                                        <Link to={global.variables.strDomainUrl + "research-project/" + val.rcategory_id} className="nav-link h-100 d-flex flex-column">
                                                            <figure className="p-3 p-md-0">
                                                                <img src={global.variables.strApiUrl + "/researchcategory-image/"  + val.rcategory_icon} className="img-fluid mb-3 wow zoomIn" alt={val.rcategory_name} title={val.rcategory_name} />
                                                                <h6 className="wow fadeInUp">{val.rcategory_name}</h6>
                                                                {
                                                                    val.rcategory_intro_text != null && val.rcategory_intro_text != "" ? 
                                                                    <p>{val.rcategory_intro_text}</p>
                                                                    : null
                                                                }  
                                                            </figure>
                                                            <div className="text-uppercase mt-auto wow fadeInUp d-flex justify-content-center align-items-center btnlink">
                                                                View Projects<BsChevronRight className="d-md-inline-block ms-1 d-none" />
                                                            </div>
                                                            </Link>
                                                        </div>
                                                </Col>

                                            )
                                          

                                        })
                                    }
                                        
                                </Row>
                                :null
                             :
                            <Row>
                            <span className="text-center">Loading...</span>
                            </Row>
                    }
                  
                </Container>
            </div>

            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Research</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};