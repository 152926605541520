import { useState, useEffect } from "react";
import globalVariables from "../../global-variables";
import { Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
    BsChevronRight,
    BsLink45Deg,
    BsImages,
    BsFilePdf,
    BsPlayBtn,
} from "react-icons/bs";
import { GetEventOnHomePageList } from "../../api/events-functions";
import { GetEventsGalleryList } from "../../api/events-gallery-functions";
import FsLightbox from "fslightbox-react";

import { TbMap2, TbCalendarTime } from "react-icons/tb";

export default function EventBlock() {

    // constant to be used on the page
    const [events, setEventsList] = useState(null);
    
    const [events_gallery_toggler, setEventsGalleryToggler] = useState(false);
    const [events_gallery, setEventsGallery] = useState([]);


    const [videos, setvideosToggler] = useState(false);
    const [lightbox_video, setLightBoxVideo] = useState([]);

    const [gallery_key, setGalleryKey] = useState("");

    const navigate =  useNavigate();

      // success function when the list is retrived
      const OnGetEventsSuccess = (p_response) => {
        // set the list for looping
        setEventsList(p_response);
    }

    // success function when events gallery is retrived
    const OnGetEventsGallerySuccess = (p_response) => {

        let _arrGallery = [];

        // loop and make the array for binding the gallery
        for (let i = 0; i < p_response.length; i++) {
            _arrGallery.push(globalVariables.variables.strApiUrl + "/events-gallery-image/" + p_response[i].egallery_pic);
        }

        // set the array in the constant
        setEventsGallery(_arrGallery);

        setGalleryKey(Date.now())

        // using time out for the value to get saved in state 
        setTimeout(() => {
            // trigger the link click to display the news gallery popup
            document.getElementById("lnk_trigger_events_gallery_popup_home").click();
        }, 200);

    }


    // function to invoke photo gallery
    const InvokePhotoGallery = (e, p_event_id) => {
        e.preventDefault();

        // Make an object for getting news gallery
        let _objEventsGallery = new Object();
        _objEventsGallery.egallery_status = 1;
        _objEventsGallery.egallery_event_id = p_event_id;

        // call the function to get the news gallery
        GetEventsGalleryList(_objEventsGallery, OnGetEventsGallerySuccess,navigate);

    };

     // function to invoke video popup
     const InvokeVideoPopup = (e, p_event_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_event_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_home_event").click();

    };

      // things to be done when the page is loaded
      useEffect(() => {

        // call the function to get the roadblock
        GetEventOnHomePageList(OnGetEventsSuccess,navigate);

    }, []);

    return(
        <>
            {
                events != null ?

                    events.length > 0 ?
            
                        <div className="ps-xl-5 col-xl-6">
                                        <div className="hd mb-3 mb-lg-5 wow fadeInRight">
                                            <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                                                <span>C-MInDS</span> Events / Seminars
                                            </h2>
                                        </div>

                                        <Row className="row-cols-1 g-4 g-lg-5 ps-md-3">
                                            {
                                               events.map((val)=>{
                                                    return(
                                                        <Col>
                                                            <div className="events">
                                                                <div className="d-md-flex border border-primary d-block">
                                                                    <div className="py-md-3 pb-3">
                                                                        <div className="dates lh-sm d-md-block d-flex">

                                                                            {/*when from and to date are diffrent  */}

                                                                            { 
                                                                                val.news_date != val.news_to_date ? 
                                                                                      <>

                                                                                            {
                                                                                                val.from_mon != val.to_mon ? 
                                                                                                <>
                                                                                                        <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                                        {val.from_day}
                                                                                                        </h2>
                                                                                                            <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                            {val.from_mon} {val.from_year}
                                                                                                            
                                                                                                        </h6>
                                                                                                        <p className="my-2">To</p>
                                                                                                        <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                                            {val.to_day}
                                                                                                        </h2>
                                                                                                        <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                            {val.to_mon} {val.to_year}
                                                                                                        </h6>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                                        {val.from_day}-{val.to_day}
                                                                                                    </h2>
                                                                                                        <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                        {val.from_mon}<br className="d-md-block d-none" /> {val.from_year}
                                                                                                        
                                                                                                    </h6>
                                                                                                </>
                                                                                                
                                                                                            }
                                                                                            
                                                                                    </>
                                                                                    
                                                                                :
                                                                               // when from and to date same 
                                                                                <>
                                                                                    {
                                                                                         //when from and to date's month is same
                                                                                        val.from_mon != val.to_mon ? 
                                                                                        <>
                                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                            {val.from_day}
                                                                                            </h2>
                                                                                                <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                {val.from_mon} {val.from_year}
                                                                                                
                                                                                            </h6>
                                                                                            <p className="my-2">To</p>
                                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                                {val.to_day}
                                                                                            </h2>
                                                                                            <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                {val.to_mon} {val.to_year}
                                                                                            </h6>
                                                                                         </>
                                                                                        :
                                                                                        <>
                                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                                {val.from_day}
                                                                                            </h2>
                                                                                                <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                                {val.from_mon} <br className="d-md-block d-none" />{val.from_year}
                                                                                            </h6>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                
                                                                                
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 px-xl-5 px-4 py-3 d-flex flex-column">
                                                                        <h5 className="fw-bold lh-sm mb-3 wow fadeInUp">
                                                                           {val.event_name}
                                                                        </h5>
                                                                        <div className="mb-4 d-flex">
                                                                        {
                                                                            val.event_venue != null && val.event_venue != "" ? 
                                                                            <div className="wow fadeInUp">
                                                                                <TbMap2 className="h5 m-0 text-primary" />{" "}
                                                                                {val.event_venue}
                                                                            </div> : 
                                                                            null
                                                                        }
                                                                        {
                                                                          val.event_time != null && val.event_time != "" ?
                                                                          <div className="ms-2 wow fadeInUp">
                                                                            <TbCalendarTime className="h5 m-0 text-primary" />{" "}
                                                                            {val.event_time}
                                                                           </div> :
                                                                           null
                                                                        }
                                                                            
                                                                           
                                                                        </div>
                                                                        <div className="mt-auto">
                                                                            <div className="d-flex gap-3">

                                                                            {/* link */}
                                                                            {
                                                                                val.event_url != null && val.event_url != "null" && val.event_url != "" ?
                                                                                <a href={val.event_url} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsLink45Deg className="h6 m-0" /></a>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                            {/* Photo Gallery */}
                                                                            {
                                                                                parseInt(val.event_gallery_count) > 0 ?
                                                                                <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokePhotoGallery(event, val.event_id)}><BsImages className="h6 m-0" /></a>
                                                                                :
                                                                                null
                                                                            }
                                                                            {/* Document */}
                                                                            {
                                                                                val.event_document != null && val.event_document != "null" && val.event_document != "" ?
                                                                                    <a href={globalVariables.variables.strApiUrl + "/events-documents/" + val.event_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0"><BsFilePdf className="h6 m-0" /></a>
                                                                                    :
                                                                                    null
                                                                            }


                                                                             {/* Video */}
                                                                            {
                                                                                val.event_video_link != null && val.event_video_link != "null" && val.event_video_link != "" ?
                                                                                    <a className="btn btn-sm btn-outline-primary rounded-0" onClick={event => InvokeVideoPopup(event, val.event_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                               })     
                                            }



                                        </Row>

                                        <div className="mt-lg-5 mt-4 wow fadeInUp d-flex justify-content-between">
                                            <Link
                                                to={globalVariables.variables.strDomainUrl + "seminars/recent"}
                                                className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal"
                                            >
                                            All Seminars
                                            </Link>
                                            <Link
                                                    to={globalVariables.variables.strDomainUrl + "events/recent"}
                                                    className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal"
                                                >
                                                All Events
                                            </Link>
                                        </div>
                        </div>
                        :
                        null

                    :
                    <div className="mt-lg-5 mt-4 wow fadeInUp">
                        Loading ...
                    </div>
            }


            {/* Video popup */}
            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setvideosToggler(!videos);
            }}
                className="d-none"
                id="lnk_trigger_video_popup_home_event"></a>

            <FsLightbox
                toggler={videos}
                sources={lightbox_video}
            />

            {/* News Gallery popup */}
            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setEventsGalleryToggler(!events_gallery_toggler);
            }}
                className="d-none"
                id="lnk_trigger_events_gallery_popup_home"></a>

            <FsLightbox
                toggler={events_gallery_toggler}
                sources={events_gallery}
                key={gallery_key}
            />

            
        </>

           

            
    )
}