import React, { useEffect, useState , useRef} from 'react';
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";
import Tabs from '@mui/material/Tabs';
import Tab, { tabClasses } from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { GetPeoples } from './GetPeoples';
import { Global } from '@emotion/react';

import Metatag from "../../hooks/Metatag";

import $ from   'jquery';


export default function People() {
    
    //popup
    const [show, setShow] = useState(false);
    const [getfacultyid, setFacultyid] = useState('');

    const [peoplecategory, setPeopleCategory] = useState(null);

    const [PcategoryId,setpcategoryId] = useState();

    const [subcategory, SetSubcategoryList] = useState([]);

    const [blnHaspeople, setblnHaspeople] = useState(false);

    const[getindex,setindex] = useState(0);

    const handleClose = () => setShow(false);
    const navigate =  useNavigate();
    
    const handleShow = (e, faculty_id) => {
        e.preventDefault();
        setShow(true);
        //get faculty id
        setFacultyid(faculty_id);
    }

    useEffect(() => {
        //get peoplecategory list
        axios.post(global.variables.strApiUrl + '/peoplecategoryApi/getcategoryHavingPeopleList')
            .then((response_category) => {
                
                if(response_category.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setTimeout(() => { 
                        setPeopleCategory(response_category.data[0])
                        ; }, 100)
    
                        //if category is Founders and Donors set 1st index value 1 else 0
                        if(global.variables.strPeopleCategoty_id === response_category.data[0][0]?.pcategory_id)
                        {
                            setindex(1);
                        }
                    setpcategoryId(response_category.data[0][0]?.pcategory_id);
                }

                
            });


    }, [])

 

    useEffect(()=>{
        let _obj = new Object();
   
        _obj.ps_category_status = 1;
        _obj.pcategory_id = PcategoryId ;
        
           axios.post(global.variables.strApiUrl + '/peoplesubcategoryApi/getpeopleSubCategoryList', _obj)
               .then((response_subcategory) => {

                if(response_subcategory.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    //set true when people count grater than zero 
                    for (let i = 0; i < (Object.keys(response_subcategory.data[0]).length); i++) {
                        if (response_subcategory.data[0][i].ps_people_count > 0) {
                            setblnHaspeople(true);
                        }
                    }
    
                    SetSubcategoryList(response_subcategory.data[0]);
                }
            });

    },[PcategoryId])


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const linkListRef = useRef(null);
    
    useEffect(() => {
      // Access the first link element when the component mounts
      if(linkListRef.current)
      {
        
          if(linkListRef.current?.querySelector('.active') != null)
          {
              const firstLink = linkListRef.current?.querySelector('.active');

                firstLink.click()
          }
   
      }
  
    },[peoplecategory]);

    return (
        <>
            <Header main_class="bg-header-home"></Header>


            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-people.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">People</h1>
                    </Container>
                </div>
            </div>
            <div className="container-fluid my-xl-5 my-4 px-0">
                {
                    peoplecategory != null ?
                        peoplecategory.length > 0 ?
                            <Container className="py-md-4">
                                    <Container className='d-xl-block d-none'>
                                                <div ref={linkListRef} className="d-flex justify-content-center people-nav mb-5" >
                                                    {
                                                        peoplecategory.map((val,index)=>{
                                                            return(
                                                                index === getindex?
                                                                    <>
                                                                        {
                                                                           val.pcategory_id === global.variables.strPeopleCategoty_id ? null
                                                                           : <Link to={global.variables.strDomainUrl + 'people/' + val.pcategory_pagename} className='active' id={"tab_" + val.pcategory_id}>{val.pcategory_name}</Link>
                                                                        } 
                                                                    </>
                                                                    :
                                                                    <>
                                                                       {
                                                                           val.pcategory_id === global.variables.strPeopleCategoty_id ? null 
                                                                           :<Link to={global.variables.strDomainUrl + 'people/' + val.pcategory_pagename}>{val.pcategory_name}</Link>
                                                                       }
                                                                    </>
                                                            )

                                                        })    
                                                    }
                                                </div>
                                            </Container>
                                
                                <Container className='people-content'>
                                    
                                    <Accordion defaultActiveKey="0" flush id="accordionExample">
                                        {
                                            subcategory != null ?
                                            blnHaspeople === true ?
                                            subcategory.length > 0 ?
                                                subcategory.map((val_subcategory,index)=>{

                                                    return(
                                                        <>
                                                            {
                                                            
                                                                //get 0 index of value 
                                                                    val_subcategory.ps_pcategory_id === PcategoryId ?
                                                                    val_subcategory.ps_people_count > 0 ?

                                                                        <Accordion.Item eventKey={index.toString()} key={val_subcategory.ps_category_id} >
                                                                            <Accordion.Header  onClick={() => { window.scrollTo({ top: 480, left: 0, behavior: 'smooth' }); }}><h5 className='m-0'>{val_subcategory.ps_category_name}</h5></Accordion.Header>
                                                                            <Accordion.Body className='people'>
                                                                                <GetPeoples subcategory_id={val_subcategory.ps_category_id} />
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                        :
                                                                        null
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                        
                                                       
                                                    )

                                                })
                                                : <span className="text-center">No records found</span>
                                                : <span className="text-center">Loading...</span>
                                            : <span className="text-center">No records found</span>
                                        }
                                       
                                    </Accordion>
                                </Container>
                                
                            </Container>
                         :
                         <Container className="my-5">
                             <Row>
                             <span className="text-center"><b>No records found</b></span>
                             </Row>
                         </Container>
                     
                     :
                     <Container>
                         <Row>
                         <span className="text-center">Loading...</span>
                         </Row>
                     </Container>
                }
            </div >

            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>People</Breadcrumb.Item>
            </Breadcrumb>

        </>
    );
};