import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Metatag from "../../hooks/Metatag";
import { useLocation,useParams } from "react-router-dom";
import DefaultImg from '../../assets/default_img/people-default.jpg';
import ResDefaultImg from '../../assets/default_img/research-default.jpg';

export default function ResearchDetails() {

    
    const [researchMapPeople, setResearchMapPeopleList] = useState([]);
    
    const [research, setResearchList] = useState([]);

    let {res_pagename} = useParams();
    const qs_res_pagename = res_pagename;

    const navigate = useNavigate();

    //research categoty based project list
    useEffect(() => {
        let _obj = new Object();
        _obj.res_pagename = qs_res_pagename;
        //get research category list
        axios.post(global.variables.strApiUrl + '/researchApi/getresearchList',_obj)
            .then((response_research) => {
                if(response_research.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setResearchList(response_research.data[0]);
                    //get research map people list
                    let _objresmap_people = new Object();
                    _objresmap_people.res_id = response_research.data[0][0].res_id;
                    //get research category list
                    axios.post(global.variables.strApiUrl + '/researchApi/getresearchMapPeopleList',_objresmap_people)
                        .then((response_research_m_ppl) => {

                            if(response_research_m_ppl.data === "error")
                            {
                                 navigate("/error-page")
                            }
                            else
                            {
                                setResearchMapPeopleList(response_research_m_ppl.data[0]);
                            }
                    }) 
                }

                }) 
    }, [])

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            {/* dangerouslySetInnerHTML={{ __html: research[0]?.res_fac_members }} */}
            {
                research[0] != null && research[0] != undefined ?
                // <Metatag title={ research[0]?.res_pro_name } keywords={research[0]?.res_pro_name + " | " + research[0]?.res_fac_members} description={research[0]?.res_pro_name + " | " + research[0]?.res_fac_members}/>
                <Metatag title={ research[0]?.res_pagetitle }
                            keywords={research[0]?.res_meta_keywords}
                            description={research[0]?.res_meta_description}/>
                :null
            }

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-research.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                    <h1 className="fw-semibold wow fadeInUp">{research[0]?.rcategory_name}</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid my-md-5 py-4">
                <Container className="px-0">
                    <div className="border-1 border-primary">
                        <div className="h-100 p-lg-5">
                            {
                                 research != null ?
                                    research.length > 0 ? 
                                    <div className="position-relative">
                                        {
                                            research?.map((val)=>{
                                                return(
                                                    <>
                                                         <Row>
                                                                <Col md={5} lg={3} className="mb-4 mb-md-0 text-start border">

                                                                {
                                                                    val.res_thu_img != null && val.res_thu_img != "" ? 
                                                                    <img src={global.variables.strApiUrl + "/research-thumbnail/" + val.res_thu_img} className="img-fluid wow fadeIn" alt={val.res_pro_name} title={val.res_pro_name} />

                                                                    : //  default image 
                                                                    <img src={ResDefaultImg} className="img-fluid wow fadeIn" alt={val.res_pro_name} title={val.res_pro_name} />   
                                                                                
                                                                }
                                                                </Col>
                                                                <Col md={7} lg={9} className="px-xl-4">
                                                                    <div className="mb-3">
                                                                        <h5 className="text-primary wow fadeInUp">{val.res_pro_name}</h5>
                                                                        {
                                                                            val.res_fac_members != null && val.res_fac_members != "" ? 
                                                                            <p className="wow fadeInUp ck-content"><span dangerouslySetInnerHTML={{ __html: val.res_fac_members }}></span></p>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <hr className="my-5" />
                                                            <Row>
                                                                <Col md={12}>
                                                                {
                                                                   val.res_pro_details != null && val.res_pro_details != "" ? 
                                                                   <p  className="wow fadeInUp ck-content"><span dangerouslySetInnerHTML={{ __html: val.res_pro_details }}></span></p>
                                                                    : null
                                                                }
                                                                {
                                                                    val.res_pdf != null && val.res_pdf != "" ? 
                                                                        <Link to={global.variables.strApiUrl + "/research-document/" + val.res_pdf} target='_blank' className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                                                            Download Research Information
                                                                        </Link>
                                                                    : null
                                                                }
                                                              
                                                                </Col>

                                                                {
                                                                    val.res_map_ppl_count > 0 ? 
                                                                        <Col md={12} className="mt-5">
                                                                        <div className="mb-3">
                                                                            <h5 className="mb-2">Faculty Members:</h5>
                                                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                                               
                                                                                {
                                                                                    researchMapPeople?.map((val)=>{
                                                                                        return(
                                                                                            <Col className="text-center" key={val.people_id}>

                                                                                                {                                                                                           
                                                                                                    // (val.people_publications != null && val.people_publications != "") || (val.people_research_inst != null && val.people_research_inst != "") || (val.people_profile_brief != null && val.people_profile_brief != "") ?
                                                                                                        <Link to={global.variables.strDomainUrl + "people/" + val.pcategory_pagename + "/" + val.people_pagename}>
                                                                                                            {
                                                                                                                val.people_pic != null && val.people_pic != "" ? 
                                                                                                                <img src={global.variables.strApiUrl + "/people/" + val.people_pic} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name} />
                                                                                                                : 
                                                                                                                <img src={DefaultImg} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name}/>

                                                                                                            }
                                                                                                            <small>{val.people_name}</small>
                                                                                                        </Link>
                                                                                                        //  :
                                                                                                        // <>
                                                                                                        //     {
                                                                                                        //         val.people_pic != null && val.people_pic != "" ? 
                                                                                                        //         <img src={global.variables.strApiUrl + "/people/" + val.people_pic} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name} />
                                                                                                        //         : 
                                                                                                        //         <img src={DefaultImg} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name}/>

                                                                                                        //     }
                                                                                                        //     <small>{val.people_name}</small>
                                                                                                        // </>
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                }
                                                                               
                                                                            </Row>
                                                                        </div>
                                                                         </Col>
                                                                    : null
                                                                }
                                                                 
                                                            </Row>
                                                            <div className="d-flex flex-wrap justify-content-end gap-3 mt-4">
                                                                <Link  className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal" onClick={() => navigate(-1)}>
                                                                    Back
                                                                </Link>
                                                            </div>
                                                    </>
                                                )
                                            })
                                        }  
                                    </div>
                                    :null
                                    
                                :
                                 <Container className="py-md-4 py-4">
                                    <Row>
                                        <span className="text-center">Loading...</span>
                                    </Row>
                                </Container>
                            }
                          
                        </div>
                    </div>
                </Container>
        </div>
            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel px-3">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "research"}>Research</Breadcrumb.Item>
                {
                    research != null?
                    <>
                        <Breadcrumb.Item href={global.variables.strDomainUrl + "research-project/" + research[0]?.rcategory_id}>{research[0]?.rcategory_name}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{research[0]?.res_pro_name}</Breadcrumb.Item>
                    </>
                    
                    :
                    null
                }
                
            </Breadcrumb>
        </>
    );
};