import React, { useState, useEffect } from "react";
import { Container, Row, Col, Fade} from "reactstrap";
// import Carousel from "react-bootstrap/Carousel";
import global from "../../global-variables";
import Header from "../layout/header";
import Marquee from "react-fast-marquee";
import $ from "jquery";

import {
  BsChevronRight,
  BsLink45Deg,
  BsImages,
  BsFilePdf,
  BsPlayBtn,
} from "react-icons/bs";

import { TbMap2, TbCalendarTime } from "react-icons/tb";
import CountUp from "react-countup";

import WOW from "wowjs";
import { Link } from "react-router-dom";

import SliderPro, { Arrows, Autoplay, Buttons, Caption, Layers} from 'slider-pro-js'
import 'slider-pro-js/css';


export default function Home1() {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").removeClass("bg-header-inner");
    $(".fixed-top").addClass("bg-header-home");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

    const mySlider = new SliderPro('#my-slider', {
      addOns: [Autoplay, Buttons, Arrows, Caption, Layers],
      autoSlideSize: true,
      width: 1920,
      height: 870,
      arrows: true,
      autoplay: true,
      autoScaleLayers: false,
    });

    document.addEventListener('click', () => {
      mySlider.nextSlide();
    });

  }, []);

  return (
    <>
      <Header main_class="bg-header-home"></Header>

      {/* <div className="container-fluid bg-light px-0">
        <div className="d-md-none d-block">
          <img className="d-block w-100" src={global.variables.strDomainUrl + "/images/visualpanel/mob-visual.jpg"} alt="" />
        </div>
        <div className="visual-container-1920 d-md-block d-none">
          <Carousel fade
            activeIndex={index}
            onSelect={handleSelect}
            interval={3000}
            nextIcon={false}
            prevIcon={false}
          >
            <Carousel.Item>
              <img className="d-block w-100" src={global.variables.strDomainUrl + "/images/visualpanel/visual1.jpg"} alt="" />

              <Carousel.Caption className="slider-caption">
                <Container className="d-md-block d-none">
                  <div className="caption-container">
                    <h2 className="h1 fw-bolder">
                      Provide Leadership in Machine Intelligence and Data
                      Science
                    </h2>
                    <small className="h2 d-block fw-light">
                      through Education, Research, <br />
                      and Industry Collaboration
                    </small>
                    <div>
                      <Link to="" className="btn btn-dark px-4 py-3 fw-light text-uppercase rounded-0 opacity-75 mt-4 fw-normal">Read More</Link>
                    </div>
                  </div>
                </Container>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={global.variables.strDomainUrl + "/images/visualpanel/visual2.jpg"} alt=""/>
              <Carousel.Caption className="slider-caption">
                <Container className="d-md-block d-none">
                  <div className="caption-container">
                    <h2 className="h1 fw-bolder animated">
                      Specialized Research in Artificial Intelligence, Machine
                      Learning, and related areas
                    </h2>
                    <div>
                      <Link to="" className="btn btn-dark px-4 py-3 fw-light text-uppercase rounded-0 opacity-75 mt-4 fw-normal animated">Read More</Link>
                    </div>
                  </div>
                </Container>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div> */}

      <div className="slider-pro visual-container-1920" id="my-slider">
        <div className="sp-slides">

          <div className="sp-slide">
            <img className="sp-image" src={global.variables.strDomainUrl + "images/visualpanel/visual1.jpg"} alt="" />

            <h3 className="sp-layer sp-padding" data-position="centerLeft" data-vertical="10%" data-show-transition="left" data-show-delay="300" data-hide-transition="right">
              Lorem ipsum dolor sit amet
            </h3>
            <p className="sp-layer sp-padding" data-position="centerLeft" data-vertical="22%" data-show-transition="left" data-show-delay="350" data-hide-transition="right">
              consectetur adipisicing elit
            </p>

          </div>

          <div className="sp-slide">
            <img className="sp-image" src={global.variables.strDomainUrl + "images/visualpanel/visual2.jpg"} alt="" />

            <h3 className="sp-layer sp-padding" data-position="centerLeft" data-vertical="10%" data-show-transition="left" data-show-delay="0" data-hide-transition="right">
              Lorem ipsum dolor sit amet
            </h3>
            <p className="sp-layer sp-padding" data-position="centerLeft" data-vertical="22%" data-show-transition="left" data-show-delay="0" data-hide-transition="right">
              consectetur adipisicing elit
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-ticker p-3 px-0 w-100">
        <Container>
          <Marquee pauseOnHover="true" delay="1" speed="40">
            <div className="d-flex">
              <div className="px-4">
                <Link to="/admissions">
                  <strong>14 Apr 2023:</strong> PhD in AI & DS 2023-24 - Final
                  Admit List
                </Link>
              </div>
              <div className="px-4">
                <Link to="/admissions">
                  <strong>14 Apr 2023:</strong> PhD in AI & DS 2023-24 - Final
                  Admit List
                </Link>
              </div>
              <div className="px-4">
                <Link to="/admissions">
                  <strong>14 Apr 2023:</strong> PhD in AI & DS 2023-24 - Final
                  Admit List
                </Link>
              </div>
            </div>
          </Marquee>
        </Container>
      </div>


      <div className="container-fluid px-0 my-xl-5 my-4">
        <Container className="py-md-5">
          <div className="border-box-1">
            <Row className="px-md-3">
              <Col className="col-lg-7 col-xl-8">
                <div className="h-100 py-md-5">
                  <div className="d-flex flex-column h-100 z-index-2 position-relative">
                    <div className="px-md-5">
                      <div className="hd mb-4">
                        <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                          <span>About</span> C-MInDS
                        </h2>
                      </div>
                      <p className="wow fadeInUp">
                        The Centre for Machine Intelligence and Data Science
                        (C-MInDS) at IIT Bombay has been set up in February 2020
                        to contribute towards the growing significance of the
                        role of Artificial Intelligence, Data Science, and
                        related areas in different application domains. The
                        Centre aims to be engaged actively to nurture the future
                        talent pool in AI and DS primarily through research,
                        teaching, and collaboration with industry/government.
                      </p>
                      <p className="wow fadeInUp">
                        Particularly at IIT Bombay, the activities of the centre
                        are expected to leverage the existence of a strong
                        inter-disciplinary academic community and the important
                        research endeavors carried out by various researchers
                        and faculty members.
                      </p>
                      <div className="pt-4">
                        <Link to="/about" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-lg-5 col-xl-4 text-end d-lg-block d-none align-self-center z-index-2">
                <img src={global.variables.strDomainUrl + "images/pic-intro.jpg"} className="img-fluid wow fadeInRight" alt="" title="" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="container-fluid bg-light px-0 py-5">
        <Container className="my-md-4">
          <div className="hd1 text-center mb-5">
            <h2 className="fw-bolder h1 m-0 wow fadeInUp">
              <span>C-MInDS</span> at a Glance
            </h2>
          </div>
          <Row className="stats">
            <ul className="m-0">
              <li className="wow fadeInUp">
                <h2 className="fw-bolder text-primary m-0 display-2">
                  <CountUp start={0} end={45} enableScrollSpy />+
                </h2>
                <h5 className="fw-normal">Research Projects</h5>
              </li>
              <li className="wow fadeInUp">
                <h2 className="fw-bolder text-primary m-0 display-2">
                  <CountUp start={0} end={75} enableScrollSpy />+
                </h2>
                <h5 className="fw-normal">Associated Faculty</h5>
              </li>
              <li className="wow fadeInUp">
                <h2 className="fw-bolder text-primary m-0 display-2">
                  <CountUp start={0} end={250} enableScrollSpy />+
                </h2>
                <h5 className="fw-normal">Students Graduated</h5>
              </li>
              <li className="wow fadeInUp">
                <h2 className="fw-bolder text-primary m-0 display-2">
                  <CountUp start={0} end={35} enableScrollSpy />+
                </h2>
                <h5 className="fw-normal">Partners & Collaborators</h5>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="container-fluid bg-light px-0">
        <div className="container-1920 px-0 bg-academics py-md-5">
          <Container>
            <div className="col-xl-6 ps-xl-5 ms-auto py-4  px-3">
              <div className="academics-info ms-xl-5">
                <div className="hd mb-3 mb-lg-3">
                  <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                    <span>Academics</span> Programs
                  </h2>
                </div>
                <p className="wow fadeInUp">
                  We offer comprehensive academic programs that range from
                  foundations and essentials of AI at the under-graduate level to
                  a vast breadth of advanced topics and path-breaking research at
                  the master's and doctorate levels.
                </p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item h5 fw-bold wow fadeInUp">
                    <a href="" className="d-block">
                      Doctor of Philosophy (PhD) in DS & AI
                    </a>
                  </li>
                  <li class="list-group-item h5 fw-bold wow fadeInUp">
                    <a href="" className="d-block">
                      MS by Research in DS & AI
                    </a>
                  </li>
                  <li class="list-group-item h5 fw-bold wow fadeInUp">
                    <a href="" className="d-block">
                      IDDDP in AI & DS
                    </a>
                  </li>
                  <li class="list-group-item h5 fw-bold wow fadeInUp">
                    <a href="" className="d-block">
                      Minor in AI & DS
                    </a>
                  </li>
                  <li class="list-group-item h5 fw-bold wow fadeInUp">
                    <Link to="/admissions" className="d-block">
                      Admissions
                    </Link>
                  </li>
                </ul>

                <div className="mt-lg-5 mt-4">
                  <Link to="/academics" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal text-white">Read More</Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="container-fluid px-0 my-md-5">
        <Container className="py-5">
          <div className="mb-5 text-center">
            <div className="hd1 text-center mb-3">
              <h2 className="fw-bolder h1 m-0 wow fadeInUp">
                <span>Research</span> Areas
              </h2>
            </div>
            <div className="col-lg-9 col-xl-8 m-auto wow fadeInUp">
              A detailed highlight of research, both theoretical and
              applications-oriented, being carried out by different faculty
              members at IIT Bombay can be found in the following:
            </div>
          </div>
          <Row className="row-cols-2 row-cols-md-3 row-cols-xl-5 g-4 g-lg-4">
            <Col className="text-center">
              <div className="btn-5 w-100">
                <figure className="p-0 p-md-3">
                  <img src={global.variables.strDomainUrl + "images/icon-foundation-algorithm.png"} className="img-fluid mb-3 wow zoomIn" alt="" title="" />
                  <h5 className="m-0 fw-bold wow fadeInUp">Foundation & <br />Algorithms</h5>
                </figure>
              </div>
            </Col>
            <Col className="text-center">
              <div className="btn-5 w-100">
                <figure className="p-0 p-md-3">
                  <img src={global.variables.strDomainUrl + "images/icon-machin-learning.png"} className="img-fluid mb-3 wow zoomIn" alt="" title="" />
                  <h5 className="m-0 fw-bold wow fadeInUp">Machine <br />Learning</h5>
                </figure>
              </div>
            </Col>
            <Col className="text-center">
              <div className="btn-5 w-100">
                <figure className="p-0 p-md-3">
                  <img src={global.variables.strDomainUrl + "images/icon-natural-language.png"} className="img-fluid mb-3 wow zoomIn" alt="" title="" />
                  <h5 className="m-0 fw-bold wow fadeInUp">Natural <br />Language</h5>
                </figure>
              </div>
            </Col>
            <Col className="text-center">
              <div className="btn-5 w-100">
                <figure className="p-0 p-md-3">
                  <img src={global.variables.strDomainUrl + "images/icon-computer-vision.png"} className="img-fluid mb-3 wow zoomIn" alt="" title="" />
                  <h5 className="m-0 fw-bold wow fadeInUp">Computer <br />Vision</h5>
                </figure>
              </div>
            </Col>
            <Col className="text-center">
              <div className="btn-5 w-100">
                <figure className="p-0 p-md-3">
                  <img src={global.variables.strDomainUrl + "images/icon-deep-learning.png"} className="img-fluid mb-3 wow zoomIn" alt="" title="" />
                  <h5 className="m-0 fw-bold wow fadeInUp">Deep <br />Learning</h5>
                </figure>
              </div>
            </Col>
          </Row>

          <div className="mt-lg-5 mt-4 text-center wow fadeInUp">
            <a href="/research" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">Read More</a>
          </div>
        </Container>
      </div>

      <div className="container-fluid px-0 bg-light py-md-5">
        <Container className="py-5">
          <div className="hd1 text-center mb-5">
            <h2 className="fw-bolder h1 m-0 wow fadeInUp">
              <span>Recent</span> Publications
            </h2>
          </div>
          <Row className="row-cols-1 row-cols-md-3 row-cols-xl-5 g-4">
            <Col>
              <div className="border border-primary p-4 h-100">
                <p className="wow fadeInUp">
                  Scalable multi-product inventory control with lead time
                  constraints using reinforcement learning
                </p>
                <p className="m-0 wow fadeInUp">
                  <b>Harshad Khadilkar</b>
                  <br />Aerospace Engineering
                </p>
              </div>
            </Col>
            <Col>
              <div className="border border-primary p-4 h-100">
                <p className="wow fadeInUp">
                  Longer amplicons provide better sensitivity for
                  electrochemical sensing of viral nucleic acid in water samples
                  using PCB electrodes
                </p>
                <p className="m-0 wow fadeInUp">
                  <b>Kiran Kondabagil</b>
                  <br />Bioscience & Bioengineering
                </p>
              </div>
            </Col>
            <Col>
              <div className="border border-primary p-4 h-100">
                <p className="wow fadeInUp">
                  SSMTReID-Net: Multi-Target Unsupervised Domain Adaptation for
                  Person Re-Identification
                </p>
                <p className="m-0 wow fadeInUp">
                  <b>Biplab Banerjee</b>
                  <br />Centre of Studies in Resources Engineering
                </p>
              </div>
            </Col>
            <Col>
              <div className="border border-primary p-4 h-100">
                <p className="wow fadeInUp">
                  Potentiating the Anti-Tuberculosis Efficacy of Peptide Nucleic
                  Acids through Combinations with Permeabilizing Drugs
                </p>
                <p className="m-0 wow fadeInUp">
                  <b>Sarika Mehra</b>
                  <br />Chemical Engineeirng
                </p>
              </div>
            </Col>
            <Col>
              <div className="border border-primary p-4 h-100">
                <p className="wow fadeInUp">
                  Iridium-Catalyzed Ligand-Controlled Remote para-Selective C-H
                  Activation and Borylation of Twisted Aromatic Amides
                </p>
                <p className="m-0 wow fadeInUp">
                  <b>R. B Sunoj</b>
                  <br />Chemistry
                </p>
              </div>
            </Col>
          </Row>

          <div className="mt-lg-5 mt-4 text-center wow fadeInUp">
            <a href="" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">View All</a>
          </div>
        </Container>
      </div>

      <div className="container-fluid my-md-5 px-0">
        <Container className="py-5">
          <div className="row gy-5">
            <div className="pe-xl-5 col-xl-6">
              <div className="hd mb-3 mb-lg-5 wow fadeInRight">
                <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                  <span>C-MInDS</span> News
                </h2>
              </div>
              <Row className="row-cols-1 g-4 g-lg-5">
                <Col className="news">
                  <div className="d-md-flex bg-light position-relative overflow-hidden h-100 wow fadeInUp">
                    <div className="pic wow fadeInUp">
                      <Link to="/news" className="nav-link">
                        <img
                          src={
                            global.variables.strDomainUrl + "images/news/news1.jpg"
                          }
                          className="img-fluid w-100"
                          alt=""
                          title=""
                        />
                      </Link>
                    </div>
                    <div className="d-flex justify-content-center flex-grow-1 wow fadeInUp">
                      <div className="px-4 py-md-3 py-4 w-100">
                        <Link to="/news" className="nav-link">
                          <h5 className="fw-bold lh-sm">
                            Dr. V. Narayanan inaugurates TechConnect 2022
                          </h5>
                        </Link>
                        <i className="mb-2">16 Dec 2022</i>
                        <div className="d-flex gap-3 mt-2">
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsLink45Deg className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsImages className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsFilePdf className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsPlayBtn className="h6 m-0" />
                          </Link>
                        </div>
                      </div>
                      <Link
                        to="/news"
                        className="btnlink bg-primary d-flex align-items-center justify-content-center wow fadeInUp"
                      >
                        <BsChevronRight />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col className="news">
                  <div className="d-md-flex bg-light position-relative overflow-hidden h-100 wow fadeInUp">
                    <div className="pic wow fadeInUp">
                      <Link to="/news" className="nav-link">
                        <img
                          src={
                            global.variables.strDomainUrl + "images/news/news2.jpg"
                          }
                          className="img-fluid w-100"
                          alt=""
                          title=""
                        />
                      </Link>
                    </div>
                    <div className="d-flex justify-content-center flex-grow-1 wow fadeInUp">
                      <div className="px-4 py-md-3 py-4 w-100">
                        <Link to="/news" className="nav-link">
                          <h5 className="fw-bold lh-sm">
                            IIT Bombay Celebrates Alumni Day 2022
                          </h5>
                        </Link>
                        <i className="mb-2">16 Dec 2022</i>
                        <div className="d-flex gap-3 mt-2">
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsLink45Deg className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsImages className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsFilePdf className="h6 m-0" />
                          </Link>
                          <Link
                            to="/news"
                            className="btn btn-sm btn-outline-primary rounded-0"
                          >
                            <BsPlayBtn className="h6 m-0" />
                          </Link>
                        </div>
                      </div>
                      <Link
                        to="/news"
                        className="btnlink bg-primary d-flex align-items-center justify-content-center wow fadeInUp"
                      >
                        <BsChevronRight />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="mt-lg-5 mt-4 wow fadeInUp">
                <Link
                  to="/news"
                  className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal"
                >
                  View All
                </Link>
              </div>
            </div>

            <div className="ps-xl-5 col-xl-6">
              <div className="hd mb-3 mb-lg-5 wow fadeInRight">
                <h2 className="fw-bolder h1 m-0 wow fadeInRight">
                  <span>C-MInDS</span> Events
                </h2>
              </div>

              <Row className="row-cols-1 g-4 g-lg-5 ps-md-3">
                <Col>
                  <div className="events">
                    <div className="d-md-flex border border-primary d-block">
                      <div className="py-md-3 pb-3">
                        <div className="dates lh-sm d-md-block d-flex">
                          <h2 className="fw-bolder h1 m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                            15
                          </h2>
                          <h6 className="fw-normal m-0 wow fadeInUp">
                            Jan <br className="d-md-block d-none" />
                            2023
                          </h6>
                        </div>
                      </div>
                      <div className="flex-grow-1 px-xl-5 px-4 py-3 d-flex flex-column">
                        <h5 className="fw-bold lh-sm mb-3 wow fadeInUp">
                          Workshop on Hindsight Learning for Adaptive Systems
                        </h5>
                        <div className="mb-4 d-flex">
                          <div className="wow fadeInUp">
                            <TbMap2 className="h5 m-0 text-primary" />{" "}
                            IITB-Seminar Hall
                          </div>
                          <div className="ms-2 wow fadeInUp">
                            <TbCalendarTime className="h5 m-0 text-primary" />{" "}
                            11:00 AM
                          </div>
                        </div>
                        <div className="mt-auto">
                          <div className="d-flex gap-3">
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsLink45Deg className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsImages className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsFilePdf className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsPlayBtn className="h6 m-0" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="events">
                    <div className="d-md-flex border border-primary d-block">
                      <div className="py-md-3 pb-3">
                        <div className="dates lh-sm d-md-block d-flex">
                          <h2 className="fw-bolder h1 m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                            8-9
                          </h2>
                          <h6 className="fw-normal wow m-0 fadeInUp">
                            Apr <br className="d-md-block d-none" />
                            2023
                          </h6>
                        </div>
                      </div>
                      <div className="flex-grow-1 px-xl-5 px-4 py-3 d-flex flex-column">
                        <h5 className="fw-bold lh-sm mb-3 wow fadeInUp">
                          AI.Impact - IIT Bombay Workshop
                        </h5>
                        <Row className="mb-2">
                          <div className="col-md-7 wow fadeInUp">
                            <TbMap2 className="h5 m-0 text-primary" />{" "}
                            IITB-Campus
                          </div>
                        </Row>
                        <div className="mt-auto">
                          <div className="d-flex gap-3">
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsLink45Deg className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsImages className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsFilePdf className="h6 m-0" />
                            </Link>
                            <Link
                              to="/events"
                              className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"
                            >
                              <BsPlayBtn className="h6 m-0" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="mt-lg-5 mt-4 wow fadeInUp">
                <a
                  href="/events"
                  className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal"
                >
                  View All
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}