import React from "react";
import Header from "../layout/header";
import { useState,useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import globalVariables from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import { Link , useNavigate , useParams } from "react-router-dom";
import { GetNewsLetterDistinctYearList } from "../../api/newsletter-distinct-year-function";
import { GetNewsLetterList } from "../../api/newsletter-function";
import Metatag from "../../hooks/Metatag";
import NewsLetterYearWiseFilter from "../../common/NewsLetterYearWiseFilter";
import axios from "axios";

export default function Newsletter() {

    let { nletter_year } = useParams();
	const qs_nletter_year = nletter_year;

     // constant to be used on the page
     const [newsletter, setNewsLetterList] = useState(null);

    const [newsletteryear, setNewsLetterYearList] = useState([]);

    const navigate =  useNavigate();

    // success function when the list is retrived
    const OnGetNewsLetterSuccess = (p_response) => {
       // set the list for looping
       setNewsLetterList(p_response);
   }

   useEffect(()=>{

    setTimeout(() => {

        //call the function to get the newsletter
        // make the object for getting the newsletter list
        let _objNewsLetter = new Object();
        _objNewsLetter.nletter_id = null;
        _objNewsLetter.nletter_status = 1;
        _objNewsLetter._selected_year = qs_nletter_year;
        // call the function to get the newsletter list
        GetNewsLetterList(_objNewsLetter, OnGetNewsLetterSuccess,navigate,navigate);

    }, 100);

   },[qs_nletter_year])


   useEffect(()=>{
        setTimeout(() => {
            // set year wise value for the dropdown 
            if (qs_nletter_year != undefined && qs_nletter_year != null && qs_nletter_year != "") {

                document.getElementById("ddlNLetterYear").value = qs_nletter_year;
            }
        }, 100);
    },[newsletter])

    // things to be done when the page is loaded
    useEffect(() => {
         //make the object for getting the report year list
         let _objNLetterYear = new Object();
         _objNLetterYear.nletter_status = 1;

        //Get news type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/nletterApi/getnletterdistinctyear", _objNLetterYear)
        .then((response_list) => {

            if(response_list.data === "error")
            {
                navigate("/error-page")
            }
            else
            {
                // pass the result in the success function of the respective page
                setNewsLetterYearList(response_list.data[0]);
                
            }
            
        });

    }, []);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="CMinds IIT Bombay Newsletter | AI-ML Newsletters"
                     keywords="CMinds IIT Bombay Newsletter, AI-ML Newsletters, Newsletters on Artificial Intelligence, Newsletters on Machine Learning, Newsletters on Data Science"
                     description="Newsletters on Artificial Intelligence, Machine Learning, Data Science by CMinds IITB."/>

            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-newsletter.jpg')", backgroundPosition: 'center' }}>
                    <img src={globalVariables.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Newsletter</h1>
                        <div className="ms-4 wow fadeInUp">
                            <NewsLetterYearWiseFilter yearList={newsletteryear} _id={"ddlNLetterYear"} navigate_url={"newsletter"}/>
                        
                        </div>
                    </Container>
                </div>
            </div>
            {
                newsletter != null ? 

                newsletter.length > 0 ?
                  
                    <div className="container-fluid my-md-5">
                        <Container className="py-5 newsletter">
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-4 g-md-5 g-xl-5 g-4">
                                {
                                    newsletter.map((val) => {
                                        return(
                                            <>
                                                {
                                                 //image 
                                                    val.nletter_thumbnail != null && val.nletter_thumbnail != "" ?
                                                    <Col className="text-center" key={val.nletter_id}>
                                                        <div className="border border-primary bg-light px-md-3 py-md-4 p-lg-3 px-xl-3 py-xl-4 p-3 wow fadeIn">
                                                            {
                                                                <Link to = {globalVariables.variables.strApiUrl + "/newsletter-document/" + val.nletter_document} target="_blank">
                                                                    <div className="shiny position-relative mb-3">
                                                                        <div className="bg-pic-newsletter">
                                                                            <img src={globalVariables.variables.strApiUrl + "/newsletter-thumbnail/" + val.nletter_thumbnail} className="img-fluid bg-img mb-3 wow fadeIn" alt={val.nletter_title} title={val.nletter_title} />
                                                                            <img src={globalVariables.variables.strDomainUrl + "images/bg-newsletter.png"} className="w-100 mb-3"/>
                                                                        </div>
                                                                    </div>
                                                                    {/* title */}
                                                                    <h2 className="h5 m-0 wow fadeInUp">{val.nletter_title}</h2>
                                                                    
                                                                    <i className="text-dark-50 fw-semibold wow fadeInUp">{val.nletter_date}</i>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col className="text-center">
                                                        <div className="border border-primary bg-light px-md-3 py-md-4 p-lg-3 px-xl-3 py-xl-4 p-3 wow fadeIn">
                                                            <Link to = {globalVariables.variables.strApiUrl + "/newsletter-document/" +val.nletter_document} target="_blank">
                                                                <div className="shiny position-relative mb-3">
                                                                    <div className="bg-pic-newsletter">
                                                                        <img src={globalVariables.variables.strDomainUrl + "images/newsletter/default-newsletter.png"} className="img-fluid bg-img mb-3 wow fadeIn" alt={val.nletter_title} title={val.nletter_title} />
                                                                        <img src={globalVariables.variables.strDomainUrl + "images/bg-newsletter.png"} className="w-100 mb-3"/>
                                                                    </div>
                                                                </div>
                                                                <h2 className="h5 m-0 wow fadeInUp">{val.nletter_title}</h2>
                                                                    
                                                                <i className="text-dark-50 fw-semibold wow fadeInUp">{val.nletter_date}</i>
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                }
                                            </>
                                        )
                                       
                                    })
                                }

                            </Row>
                        </Container>
                    </div>
                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container >
                            <Row>
                                <span className="text-center">Newsletters will be posted in due course</span>
                            </Row>
                        </Container>
                    </div>
                :
                <div className="container-fluid px-0 my-md-5 my-4">
                    <Container>
                        <Row>
                            <span className="text-center">Loading...</span>
                        </Row>
                    </Container>
                </div>
            }     
            <Breadcrumb className="breadcrumb-panel">
            <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
                <Breadcrumb.Item active>Newsletter</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};