import React from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";

export default function About() {
    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="C-MInDS at IIT-B | Centre for Machine Intelligence and Data Science"
                     keywords="C-MInDS at IIT-B, Centre for Machine Intelligence and Data Science, C-MInDS Academic Programs, Under Graduate AI-ML Academic Programs, Advanced AI-ML Academic Programs, Post Graduate AI-ML Programs, Doctorate AI-ML Academic Programs"
                     description="C-MInDS at IIT Bombay offers comprehensive academic programs ranging from foundations and essentials of AI at the under-graduate level to a breadth of advanced courses and research at the post-graduate and doctorate levels."/>

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-about.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">About C-MInDS</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid py-md-5" id="vision">
                <Container className="py-4">
                    <Row className="row-cols-1 row-cols-lg-2 g-5 px-0">
                        <Col className="pe-lg-5">
                            <div className="d-flex align-items-center mb-4">
                                <div>
                                    <img src={global.variables.strDomainUrl + "images/icon-vision.png"} className="img-fluid wow fadeInUp" alt="" title="" />
                                </div>
                                <div className="flex-grow-1 ms-3 heading">
                                    <h2 className="h1 fw-bolder m-0 wow fadeInUp"><span>C-MInDS</span> Vision</h2>
                                </div>
                            </div>
                            <p className="wow fadeInUp">Advance knowledge and provide thought-leadership in Machine Learning and AI through academics, research, and collaboration.</p>
                        </Col>
                        <Col className="ps-lg-5">
                            <div className="d-flex align-items-center mb-4">
                                <div>
                                    <img src={global.variables.strDomainUrl + "images/icon-mission.png"} className="img-fluid wow fadeInUp" alt="" title="" />
                                </div>
                                <div className="flex-grow-1 ms-3 heading">
                                    <h2 className="h1 fw-bolder m-0 wow fadeInUp"><span>C-MInDS</span> Mission</h2>
                                </div>
                            </div>
                            <p className="wow fadeInUp">CMInDS aims to establish and maintain an outstanding academic program, advance multidisciplinary AI-ML research, foster robust industry partnerships to address cutting-edge challenges, upgrade the industrial workforce through educational outreach initiatives, and tackle impactful public-facing grand challenge problems, leveraging the  multidisciplinary research culture at IIT Bombay.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="container-fluid px-0 bg-about py-md-5">
                <Container className="p-lg-0 py-4 px-4">
                    <div>
                        <Row>
                            <Col className="col-xl-7 col-xxl-9">
                                <div className="h-100 py-xxl-5">
                                    <div className="d-flex flex-column h-100 z-index-2 position-relative">
                                        <div className="px-md-5">
                                            <div className="hd mb-4">
                                                <h2 className="fw-bolder h1 m-0 wow fadeInUp"><span>About</span> C-MInDS</h2>
                                            </div>
                                            <p className="wow fadeInUp">At the Centre for Machine Intelligence and Data Science (C-MInDS), we are preparing the next generation of scientists, innovators and leaders in Machine learning and Artificial Intelligence. The centre is aimed at expanding the frontiers of AI and ML and their applications through cutting-edge research and advanced academic programs as well as strong industry collaborations.</p>
                                            <p className="wow fadeInUp">We offer comprehensive academic programs ranging from foundations and essentials of AI at the under-graduate level to a breadth of advanced courses and research at the post-graduate and doctorate levels. External collaboration is an essential tenet of our workings, and so we engage with several industries across different sectors to undertake research and development projects. We also create and support grand challenges in data and AI, wherein faculty and researchers from various domains work together to propose innovative solutions that address important social and technological limitations. Finally, we promote industry and public outreach about AI through workshops, conferences, and industry specific training programs whereby we disseminate our knowledge and discoveries, as well as contribute to upskill the nation’s human capital.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            

            {/* <div className="container-fluid px-0 bg-achievements bg-light py-md-5">
                <Container className="py-md-5 px-md-3 p-4">
                    <div className="hd mb-4">
                        <h2 className="fw-bolder h1 m-0 wow fadeInRight">Achievements</h2>
                    </div>
                    <ul className="list">
                        <li>Prof. S.C. Sahasrabudhe Lifetime Achievement Award (2022 - 23) conferred on Prof. Devang V. Khakhar</li>
                        <li>Prof. H.H. Mathur Award for Excellence in Applied Sciences for 2022 conferred on Prof. V.S. Raja</li>
                        <li>Prof. S.C. Bhattacharya Award for Excellence in Pure Sciences for 2022 conferred on Prof. Samir Maji and Prof. Debabrata Maiti</li>
                        <li>11 Distinguished Alumnus Awards and 4 Young Alumni Achiever Awards</li>
                    </ul>
                </Container>
            </div> */}

            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel px-0">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Overview</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};