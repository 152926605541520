import React, {useEffect} from 'react';
import Footer from "./footer";
import $ from "jquery";


export default function Websitelayout(props) {
    useEffect(() => {

        // define the scroll event for header
        $(window).on("scroll", function () { 
            $(".navbar").offset().top > 10 ? $(".fixed-top").addClass("top-nav-collapse") : $(".fixed-top")?.removeClass("top-nav-collapse") 
        });
       
    }, []);


    return (
        <>
        
            {props.children}
            <Footer></Footer>
        </>
    );
}