import React, { useEffect, useState } from 'react';
import axios from 'axios';
import globalVariables from '../global-variables';
import {useNavigate} from 'react-router-dom'
import { Input } from "reactstrap";

const CommonYearWiseFilter = (props) => {

    const navigate = useNavigate();

    const[year_recent_Upcoming,setyear_recent_Upcoming] =useState('recent');
    
    function OnchangeYear(e) {

        // redirecting to the supplied url
        navigate(`/${props.navigate_url}/${e.target.value}`)
    }


    return (
        <React.Fragment>
            <div className="me-3">
                <Input
                    name="selected_year"
                    type="select"
                    id={props._id}
                    className="rounded-0 shadow-none"
                    onChange={(e)=>{OnchangeYear(e)}}
                >
                    <option value="recent">Recent</option>
                    {
                        year_recent_Upcoming === 'upcoming' ? 
                            <option value="upcoming" selected>Upcoming</option>
                        :
                            <option value="upcoming">Upcoming</option>
                    }
                    {  props.yearList.length > 0 ?
                        props.yearList.map((val) => {
                            return (
                                <option value={val.event_year} key={val.event_year}>{val.event_year}</option>
                            );

                        })
                    :
                    <>
                        <option value="recent">Recent</option>
                        {
                            year_recent_Upcoming === 102 ? 
                                <option value="upcoming" selected>Upcoming</option>
                            :
                                <option value="upcoming">Upcoming</option>
                        }
                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                    
                    </>
                    }

                </Input>
            </div>
        </React.Fragment>
    )
};
export default CommonYearWiseFilter;

