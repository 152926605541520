import React from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { Link, useNavigate } from "react-router-dom";
import Metatag from "../../hooks/Metatag";

export default function CollaborationDetails() {
    return (
        <>
            <Header main_class="bg-header-home"></Header>

            <Metatag title="SBI Foundation" keywords="SBI Foundation" description="SBI Foundation" />

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-collaboration.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <Col md={6} lg={6} xxl={5}>
                            <h1 className="fw-semibold wow fadeInUp">SBI Technology Hub for Data Science and Analytics</h1>
                        </Col>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid my-md-5 py-4">
                <Container className="px-0">
                    <div className="border-1 border-primary">
                        <div className="h-100 p-lg-5">
                            <div className="position-relative">
                                <Row>
                                    <Col md={5} lg={3} className="mb-4 mb-md-0 text-start">
                                        <img src={global.variables.strDomainUrl + "images/sbi-foundation-logo.jpg"} className="img-fluid wow fadeIn" alt="SBI Foundation" title="SBI Foundation" />
                                    </Col>
                                    <Col md={7} lg={9} className="px-xl-4">
                                        <div className="mb-3">
                                            <p className="wow fadeInUp">The SBI Foundation, a CSR arm of the State Bank of India, is dedicated to enhancing the socio-economic well-being of society, particularly among marginalized communities. </p>
                                            <p className="wow fadeInUp">One of its notable initiatives is the establishment of the SBI Foundation Hub for Data Science and Analytics in partnership with IIT Bombay. This hub focuses on addressing emerging challenges in the banking sector through cutting-edge research and the application of advanced Artificial Intelligence/Machine Learning (AI/ML) and Data Science technologies. </p>
                                            <p className="wow fadeInUp">Housed within the Centre for Machine Learning and Data Sciences (CMInDS) at IIT Bombay, the hub strives to leverage data-driven insights for the benefit of the BFSI sector.</p>
                                        </div>
                                    </Col>
                                </Row>
                                <hr className="my-5" />
                                <Row>
                                    <Col md={12}>
                                        <h5 className="mb-2">Our Objectives</h5>
                                        <ul className="mb-4">
                                            <li>Bring advanced Artificial Intelligence/Machine Learning (Al/ML), and Data Science (DS) technologies for Banking, Financial Services Insurance (BFSI) through <b>collaborative projects</b></li>
                                            <li>Create a multidisciplinary <b>space</b> for innovation and ideation for industry and academia. </li>
                                            <li>Facilitate short-term <b>Executive Education programs</b> in the BFSI sector through emerging Al/ML and Data Science technologies for professionals.</li>
                                        </ul>

                                        <h5 className="mb-2">Strategic Initiatives</h5>
                                        <ul className="mb-4">
                                            <li>Implementing Research and Development projects</li>
                                            <li>Implementing Capacity Building and Training through Executive Education Program</li>
                                            <li>Exploring knowledge transfer to industry through the development of research papers and the development of technology for the dissemination of knowledge</li>
                                        </ul>

                                        <h5 className="mb-2">Expected Outcomes</h5>
                                        <p className="mb-1">Harnessing Futuristic Technology for:  </p>
                                        <ul className="mb-3">
                                            <li>Latest causal, counter-factual modelling for robust decisioning.</li>
                                            <li>Ushering digital transformation of Bank in delivery of hyper-personalized Banking and Financial services for their customers.</li>
                                            <li>Harnessing large pre-trained language models for search over internal knowledge resources.</li>
                                            <li>Latest advances in deep generative models for creating data for fraud detection, federated learning for privacy-preserving model training.</li>
                                            <li>Calibrating risk models for investment management and treasury functions of the bank.</li>
                                            <li>Data-driven and cutting-edge trading models, and personalized investment and trading experience for customers.</li>
                                        </ul>
                                        <p className="mb-1">Outreach and cross-pollination of ideas: </p>
                                        <ul className="mb-4">
                                            <li>World-class research and publications in top-tier venues.</li>
                                            <li>Workshops and seminars on cutting-edge technology.</li>
                                        </ul>

                                        <h5 className="mb-2 mt-5">People</h5>
                                        <Container className='px-0'>
                                            <h6 className="mb-2">Core Committee</h6>
                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/ravi-gudi">
                                                        <img src={global.variables.strDomainUrl + "images/ravi-gudi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Ravi Gudi" title="Prof. Ravi Gudi" />
                                                        <small>Prof. Ravi Gudi</small>
                                                    </Link>
                                                </Col>
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/d-manjunath">
                                                        <img src={global.variables.strDomainUrl + "images/d-manjunath.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. D. Manjunath" title="Prof. D. Manjunath" />
                                                        <small>Prof. D. Manjunath</small>
                                                    </Link>
                                                </Col>
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/sunita-sarawagi">
                                                        <img src={global.variables.strDomainUrl + "images/sunita-sarawagi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                        <small>Prof. Sunita Sarawagi</small></Link>
                                                </Col>
                                                <Col className="text-center col">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/piyush-pandey">
                                                        <img src={global.variables.strDomainUrl + "images/piyush-pandey.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Piyush Pandey" title="Prof. Piyush Pandey" />
                                                        <small>Prof. Piyush Pandey</small>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className='mt-5 px-0'>

                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                <Col>
                                                    <h6 className="mb-2">PI</h6>
                                                    <div className="text-center">
                                                        <Link to="https://www.minds.iitb.ac.in/people/faculty/sunita-sarawagi">
                                                            <img src={global.variables.strDomainUrl + "images/sunita-sarawagi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                            <small>Prof. Sunita Sarawagi</small></Link>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <h6 className="mb-2">Staff</h6>
                                                    <div className="text-center">
                                                        <img src={global.variables.strDomainUrl + "images/shilpa-balakrishnan.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                        <small>Shilpa Balakrishnan (Program Manager)</small>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        {/* <ul className="mb-4">
                                            <li><b>Core Committee:</b> Prof. Gudi, Prof. Manjunath, Prof. Sunita Sarawagi, Prof. Piyush Pandey</li>
                                            <li><b>PI:</b> Prof Sunita Sarawagi</li>
                                            <li><b>Staff:</b> Shilpa Balakrishnan (Program Manager)</li>
                                        </ul> */}
                                    </Col>
                                </Row>
                                <div className="d-flex flex-wrap justify-content-end gap-3 mt-4">
                                    <Link to="/collaboration" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel px-3">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "collaboration"}>Collaboration</Breadcrumb.Item>
                <Breadcrumb.Item active>SBI Technology Hub</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};