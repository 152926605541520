const Faculty = [
    {
        faculty_id:1,
        faculty_name:"Sunita Sarawagi",
        faculty_designation:"Professor In Charge, C-MInDS and Department of Computer Science and Engineering",
        faculty_research:"Machine Learning, Data Analytics, Databases and Statistics",
        faculty_url:"https://www.cse.iitb.ac.in/~sunita/",
        faculty_linkedin_url:"",
        faculty_image:"images/people/faculty/sunita-sarawagi.jpg"
    },
    {
        faculty_id:2,
        faculty_name:"Abhishek Gupta",
        faculty_designation:"Department of Mechanical Engineering",
        faculty_research:"Robotics and Automation",
        faculty_url:"",
        faculty_linkedin_url:"",
        faculty_image:"images/people/faculty/abhishek-gupta.jpg"
    },
    {
        faculty_id:3,
        faculty_name:"Abir De",
        faculty_designation:"Department of Computer Science and Engineering",
        faculty_research:"Research Interests: AI and society (including ethics, fairness, privacy, policy), Social networks, network science.",
        faculty_url:"https://abir-de.github.io/",
        faculty_linkedin_url:"",
        faculty_image:"images/people/faculty/abir-de.jpg"
    }
]
export {Faculty}